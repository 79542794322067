.ol-zoom.ol-unselectable.ol-control{
    display: none !important;
}
.ol-rotate.ol-unselectable.ol-control.ol-hidden{
    display: none !important;
}
.ol-attribution.ol-unselectable.ol-control.ol-uncollapsible{
    display: none !important;
}
.mapcontact{
    height: 530px;
}
@media(max-width: 991px){
    .mapcontact{
        height: 400px;
    }
}
@media(max-width: 575px){
    .mapcontact{
        height: 300px;
    }
}