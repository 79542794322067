.table .link:nth-child(even) {
    padding-right: 0 !important; /* Supprime le padding droit pour le dernier lien (celui à droite) */
      }


@media (max-width: 1440px) {

    /* .btn-borne {
        grid-column: 2 / span 2;
        margin-left: 28%;
    } */
}
@media (max-width: 1199px) {
    .listBotton{
     grid-template-columns: repeat(3, 1fr);
    }
    .btn-borne {
        grid-column: auto ;
        margin-left: 0%;
        grid-row:auto;
    }
    /* .btn-design{
     grid-column: auto;
     margin-left: -22%;
    grid-row: 2;
    } */

}


@media(max-width: 860px){
    .listBotton .btn {
        width: 235px !important;
    }
}

@media(max-width: 767px){
    .table .link {
        padding-right: 0 !important; 
          }
    
    .listBotton{
        grid-template-columns: repeat(2, 1fr);
       }
       .btn-borne {
        grid-column: auto;
        margin-left:0;
        grid-row: auto;
    }
    .btn-design{
        grid-column: auto;
        margin-left:0;
        grid-row: auto;
       }
       .listBotton .btn {
        width: auto !important;
    }
}
@media(max-width: 575px){
    .listBotton .btn {
        margin-bottom: 10px !important;
        padding: 10px 10px !important;
    }
    .table {
        padding: 0 20px;
    }
}