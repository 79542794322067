.testimonial{
  margin-top: 20px;
  padding: 0 !important;
  max-width: 1320px; 
  margin: 0 auto;   
  overflow: hidden; 
}

.testimonial .swiper {
  width: 100%;    
  margin-top: 25px;
}
/* .slick-slide:last-child {
  padding: 0;
} */
.slick-slide:not(.slick-cloned):last-of-type {
  margin-right: 0;
}
.testimonial .slick-dots {
  position: absolute;
  bottom: -45px;
}
.testimonial-card {
  padding: 20px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #17406F;
  border-radius: 6px;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  box-sizing: border-box; 
}


.testimonial .slick-track {
  display: flex;
  align-items: center; 
}

.testimonial-card p {
  font-size: 15px;
  color: #000;
  margin-bottom: 15px;
  flex-grow: 1;
  font-weight: 400;
}

.testimonial-footer {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  flex-direction: column;
}

.testimonial-footer .rating {
  color: #20ACE3;
  font-size: 18px;
}

.testimonial-footer .name {
  font-size: 16px;
  color: #000;
  font-weight: 500;
}
.footercarousel{
  display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;
}

.testimonial .slick-dots li button:before {
  font-family: 'slick';
  font-size: 14px;
  line-height: 38px;
  position: absolute;
  top: 0;
  left: 0;
  width: 17px;
  height: 17px;
  content: '';
  text-align: center;
  opacity: .25;
  border-radius: 50%; 
  border: 0.5px solid #17406F;
  background-color: transparent; 
  color: transparent; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.testimonial .slick-slide {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; 
  box-sizing: border-box; 
  height: 347px;
}

.testimonial .slick-track {
  display: flex;
  align-items: stretch;
}

.testimonial .slick-list {
  overflow: hidden;
}

.testimonial .slick-slide > div {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: stretch;
}

.testimonial-card {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 340px;
  padding: 20px;
  box-sizing: border-box;
}
.slick-dots li.slick-active button:before {
  opacity: 1;
  color: #17406F !important;
  background-color: #17406F !important;


}
.testimonial .swiper-pagination-bullet {
  width: 16px; 
  height: 16px; 
}
.testimonial .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next{
  transform: scale(1);
}
.testimonial .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next{
  transform: scale(0);
}
.testimonial .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev{
  transform: scale(0);
}
.testimonial .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev{
  transform: scale(1);
}

.testimonial .swiper-pagination-bullet-active {
  background-color: #004d80; 
}
@media(max-width: 1400px){
  .swiper-slide.swiper-slide-prev.testimonial-card{
    margin-right: 21px !important;
  }
}
@media(max-width: 991px){
  .testimonial-card {
    height: 294px;
  }
}
@media(max-width: 767px){
  .testimonial-card {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
   
    padding: 20px;
    box-sizing: border-box;
}
.testimonial-card svg{
  width: 57px;
    height: 43px;
}
.testimonial-card p {
  font-size: 14px;
  color: #000;
  margin-bottom: 0px;
  flex-grow: 1;
  font-weight: 400;
  line-height: 25px !important;
}
.testimonial-footer .rating {
  color: #20ACE3;
  font-size: 15px;
}
.testimonial-footer .name {
  font-size: 14px;
  color: #000;
  font-weight: 500;
}

.testimonial .swiper {
  margin-top: 14px;
}
}

@media(max-width: 576px){

.testimonial-card {
  height: 266px;
}
}