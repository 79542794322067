.carousel-item-web {
    display: flex !important;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    transition: transform 0.1s ease-in-out;
    border: 1px solid #17406F;
    height: auto;
}

.swiper {
    overflow: unset !important;
}

.carousel-image-web {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;

}

.custom-prev-web,
.custom-next-web {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
     /* z-index: 10;  */
    transition: background-color 0.3s ease;
}

.custom-prev-web {
    left: 13.5%;
}

.custom-next-web {
    right: 13.5%;
}

.carousel-container-web {
    position: relative;
}

.swiper-pagination {
    position: relative;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin-top: 34px;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 16px;
    height: 16px;
    background: #17406F !important;
}

span.swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    background: #fff;
    border: 0.5px solid #17406F
}


@media (max-width: 1200px) {
    .carousel-item p {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 30px !important;
    }

    .carousel-item h3 {
        color: #17406F;
        margin-bottom: 14px;
        font-size: 24px !important;
    }

    .carousel-item a {
        font-size: 14px !important;
    }
}

@media (max-width: 991px) {
    .carousel-item-web{
        padding: 10px;
    }
    .fourth-image {
        padding: 10px;
    }
}
@media (max-width: 576px) {
    .carousel-web {
        display: flex !important;
        align-items: center;
        padding: 20px;
        background-color: #fff;
        border-radius: 8px;
        transition: transform 0.3s ease-in-out;
        border: 1px solid #17406F;
        height: 480px;
        flex-direction: column-reverse;
        height: 416px !important;
    }

    .carousel-image-web {
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
        border-radius: 5px;
        /* max-height: 171px; */
        height: 100%;
    }

    .carousel-item h3 {
        color: #17406F;
        margin-bottom: 0px;
        font-size: 16px !important;
        line-height: 24px !important;
    }

    .carousel-item p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 28px !important;
    }

    .carousel-item a {
        font-size: 13px !important;
        padding: 12px 35px;
    }
    .carousel-item-web {
        display: flex !important;
        align-items: center;
        padding: 8px;

        background-color: #fff;
        border-radius: 8px;
        transition: transform 0.1s ease-in-out;
        border: 1px solid #17406F;

    }
    span.swiper-pagination-bullet.swiper-pagination-bullet-active {
        width: 15px;
        height: 15px;
        background: #17406F !important;
    }
    
    span.swiper-pagination-bullet {
        width: 15px;
        height: 15px;
        background: #fff;
        border: 0.5px solid #17406F
    }
    .swiper-pagination {
        margin-top: 25px;
    }

}