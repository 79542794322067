.contentp{
    width: 72%;
}
.voix-contact-page{
    margin-top: 70px;
}
.pseudo-textf {
    position: relative;
}

.btn-contact {
    color: rgb(23, 64, 111);
    background-color: transparent; 
    border: 2px solid rgb(23, 64, 111); 
    border-radius: 6px; 
    font-size: 16px;
    will-change: transform;
    transition-delay: .35s;
    border: none;
    overflow: hidden;
    display: inline-block;
    position: relative; 
    z-index: 1; 
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; 
}

.btn-contact:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    border-radius: 6px !important;
    background-color: #20ACE3;
    transition: left 0.3s ease-in-out;
    border-color: #20ACE3;
    z-index: 0; 
}

.btn-contact:hover:before {
    left: 0;
}

.btn-contact:hover {
    background-color: #20ACE3; 
    color: #fff;
    border-color: #20ACE3; 
    z-index: 2; 
}

@media (max-width: 1199px){
    .contentp {
        width: 63%;
    }
}
@media(max-width: 991px){
.img-bureau{
    padding: 28px !important;
}
.c-title {
    font-size: 18px !important;
    font-weight: 500;
}
.notre-bureau span{
    line-height: 27px;
    font-size: 15px;
}
.drapolist{
    flex-direction: column;
}
.drapolist ul{
    gap: 0px !important;
}
.drapolist ul li{
    max-height: 40px;
}
.contentp {
    width: 53%;
}
.voix-contact-page{
    margin-top: 60px;

}
}
@media(max-width: 767px){
    .img-bureau {
        padding: 0 28px !important;
        width: 90px;
    }
    .text-btn-audit{
        flex-direction: column;
    }
    .contentp{
        width: 100%;
    }
 
    .from {
        margin-top: 15px !important;
    }
}
@media(max-width: 575px){
    .c-title {
        font-size: 16px !important;
    }
    .notre-bureau span{
        line-height: 22px;
        font-size: 14px;
    }
    .notre-bureau .aLink{
        font-size: 14px;
    }
    .auditb.submit{
        font-size: 14px !important;
    }
    .from {
        margin-top: 20px !important;
    }

    .voix-contact-page{
        margin-top: 53px;
    }
  
}