.agence-cards {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  align-items: stretch;
  width: calc(100vw - 20px);
  margin-left: calc(-50vw + 50% + 10px);
  gap: 20px;
}

.agence-card {
  width: 32%;
  border: 1px solid #17406F;
  padding: 30px 30px;
  border-radius: 6px;
  text-align: left;
}
.agence-card:hover{
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  cursor: pointer;
}
.agence-card-icon {
  margin-bottom: 35px;
}

.agence-card-title {
  font-size: 26px !important;
  margin-bottom: 15px;
  font-family: 'RalewayRegular';
  font-weight: 400;
  color: #000;
  line-height: 42px !important;
}

.cardc2-agence {
  position: relative;
  top: 65px;
}

.agence-card-text {
  font-size: 16px !important;
  line-height: 30px !important;
}


.service-item-agence {
  display: flex;
  align-items: center;
  border-bottom: 1px solid #000000;
  padding: 30px 0;
  gap: 20px;
}

.service-item-one {
  border-top: 1px solid #000000;
}

.service-number-agence {
  font-size: 50px;
  color: #20ACE3;
  font-weight: 500;
  line-height: 32px;
  margin-right: 20px;
  margin-left: 20px;
  font-family: 'RalewayRegular';
}

.service-content-agence {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1 1;
  gap: 10px;
}

.service-agence-title {
  flex-shrink: 0;
  margin-right: 20px;
  width: 300px;
}

.service-agence-title h5 {
  font-size: 20px;
  font-weight: 400;
  margin: 0;
  font-family: 'RalewayRegular';
  line-height: 32px;
}

.service-content-agence p {
  font-size: 15px;
  line-height: 26px !important;
  margin: 0;
}

.points-forts-container {
  margin-top: 80px;
}

.points-forts h5 {
  color: #17406F;
  font-size: 22px;
  line-height: 35px;
  font-weight: 600;
  font-family: 'RalewayRegular';
}

.points-forts {
  margin-top: 45px;

}

.points-forts .forts {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 35px;
  padding-left: 5.5rem;
}

.forts-img-title {
  margin-bottom: 15px;
}

.service-list {
  margin-top: 20px;
}
.meet-rec-agence h3{
  font-size: 32px !important;
  font-family: "RalewayRegular";
  font-weight: 600;
  color: #000;
  margin-bottom: 10px !important;

}
.meet-rec-agence{
  margin-top: 80px;
}
@media (max-width: 991px) {
  .agence-card-title {
    font-size: 22px !important;
    line-height: 32px !important;
  }

  .agence-card-text {
    font-size: 16px !important;
    line-height: 27px !important;
  }

  .agence-card {
    padding: 18px 20px;
    flex-basis: calc(50% - 10px);
  }

  .agence-cards {
    margin-top: 10px;
    flex-wrap: wrap;
    width: auto;
    margin-left: auto;
  }
  .service-list {
    margin-top: 5px;
}

  .cardc2-agence {
    position: unset;
  }

  .agence-card {
    width: 100%;
  }

  .points-forts .forts {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    gap: 52px;
    padding-left: 0;
  }

  .points-forts h5 {
    font-size: 18px;
    line-height: 24px;
  }

  .points-forts {
    margin-top: 10px;
    margin-bottom: 22px;
  }

  .service-agence-title {
    width: auto;
  }

  

  .service-content-agence p {
    font-size: 14px;
    line-height: 23px !important;
  }

  .service-content-agence {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  .service-item-agence {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }
  .service-number-agence {
    font-size: 36px;
  }

  .points-forts-container {
    margin-top: 62px;
  }
  .meet-rec-agence{
    margin-top: 62px;
  }

  .agence-card-icon img {
    width: 70px;
  }
  .vision-notre-agence .container.vision {
    padding: 0 !important;
}
.meet-rec-agence h3 {
  font-size: 28px !important;
}
}

@media (max-width: 768px) {
  .service-number-agence {
    font-size: 33px;
    line-height: 30px;
    margin-right: 10px;
    margin-left: 15px;
  }

  .points-forts .forts {
    margin-bottom: 0px;
    gap: 0;
    flex-direction: column;
    padding-left: 0;
  }
  .meet-rec-agence h3 {
    font-size: 25px !important;
  }
}

@media (max-width: 575px) {
  .agence-cards {
    margin-top: 10px;
    width: auto;
    margin-left: auto;
  }

  .agence-card {
    margin-bottom: 0px;
    flex-basis: auto;
  }

  .points-forts .d-flex>div {
    width: 100%;
  }

  .points-forts .forts {
    flex-direction: column;
  }

  .concurrence {
    padding: 0px 20px;
  }
  .vision-notre-agence .container.vision {
    padding: 0px 20px!important;
}
  .points-forts-container {
   
    margin-top: 50px;
  }

  .points-forts {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .points-forts-container {
    padding: 0px 20px;
  }
  .service-agence-title h5 {
    font-size: 18px;
    line-height: 28px;
  }

  .points-forts .forts {
    display: flex;
    align-items: flex-start;
    margin-bottom: 10px;
    gap: 10px;
  }

  .service-agence-title {
    margin-right: 10px;

  }

  .service-number-agence {
    font-size: 28px;
    line-height: 26px;
    margin-left: 0px;

  }

  .service-item-agence {
    padding: 20px 0;
    gap: 12px;
  }

  .service-content-agence p {
    font-size: 13px;
    line-height: 22px !important;
  }

  .service-agence-title h5 {
    font-size: 16px;
    line-height: 25px;
  }

  .agence-card-title {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-bottom: 5px;
  }

  .agence-card-text {
    font-size: 14px !important;
    line-height: 26px !important;
    margin-bottom: 0px;
  }

  .service-list {
    margin-top: 10px;

  }

  .agence-card-icon {
    margin-bottom: 13px;
  }

  .agence-card-icon img {
    width: 60px;
    height: 60px;
  }

  .forts-img-title img {
    width: 52px;
    height: 52px;
  }

  .forts-img-title {
    gap: 15px !important;
  }
  .points-forts h5 {
    margin-bottom: 2px;
}
.meet-rec-agence h3 {
  font-size: 22px !important;
}
.meet-rec-agence{
  margin-top: 40px;
  padding: 0 20px;
}
 

}

@media (max-width: 480px) {
  .service-item-agence {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
  }

  .service-content-agence {
    display: flex;
    flex-direction: column;
  }

  .service-agence-title {
    width: 100%;
    margin-right: 0px;
  }

  .service-item-agence {
    padding: 20px 15px;
    gap: 10px;
  }

}