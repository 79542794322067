.faq-container-home {
  margin-top: 40px;
  display: flex;
  flex-wrap: wrap;
  gap: 18px;
  justify-content: space-between;
  align-items: flex-start;
}
.faq-item-home {
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid #17406F; 
  flex: 0 0 calc(50% - 10px);
  box-sizing: border-box;
  position: relative;
  overflow: hidden;
  transition: all 0.3s ease;
}





/* .faq-item-home::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border: 2px solid #17406F; 
  border-radius: 6px;
  transition: all 0.3s ease;
  transform: scale(0); 
  transform-origin: center;
}

.faq-item-home:hover::before {
  transform: scale(1); 
} */
/* .faq-item-home::before {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px; 
  background-color: #17406F;
  transform: scaleX(0); 
  transform-origin: bottom right;
  transition: transform 0.3s ease;
}

.faq-item-home:hover::before {
  transform: scaleX(1);
  transform-origin: bottom left;
} */



.faq-question-home {
  padding: 20px;
  font-family: 'RalewayRegular';
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  line-height: 34px;
  gap: 20px;
}

.faq-answer-home {
  padding: 0px 20px 20px 20px;
}

.faq-icon {
  font-size: 18px;
}

.faq-icon.open {
  color: blue;
}

@media (max-width: 1199px) {
  .faq-container-home {
    padding-left: 0;
  }
}

@media (max-width: 991px) {
  .faq-question-home {
    padding: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 34px;
  }

  .faq-container-home {
    margin-top: 30px;
    gap: 10px;
  }
}

@media (max-width: 680px) {
  .faq-container-home {
    display: flex;
    flex-wrap: wrap;
    gap: 18px;
    justify-content: space-between;
    flex-direction: column;
    align-items: stretch;

  }
}

@media (max-width: 767px) {
  .faq-question-home {
    padding: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 31px;
  }

  .faq-icon svg {
    width: 32px;
    height: 32px;
  }

  .faq-container-home {
    margin-top: 20px;
    gap: 0;
  }
}

@media (max-width: 575px) {
  .faq-question-home {
    padding: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 27px;
  }

  .faq-icon svg {
    width: 28px;
    height: 28px;
  }
}