.carousel-item-detail {
    display: flex !important;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    transition: transform 0.1s ease-in-out;
    border: 1px solid #17406F;
    height: auto;
}

.swiper {
    overflow: unset !important;
}

.carousel-image-detail {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;

}

/* .custom-prev-detail,
.custom-next-detail {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);

    transition: background-color 0.3s ease;
} */

.custom-prev-detail {
    left: 14.5%;
}

.custom-next-detail {
    right: 14.5%;
}

.carousel-container-detail {
    position: relative;
}

.carousel-container-detail .swiper-pagination {
    position: relative;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin-top: 25px;
}

span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 14px;
    height: 14px;
    background: #17406F !important;
}

span.swiper-pagination-bullet {
    width: 14px;
    height: 14px;
    background: #fff;
    border: 0.5px solid #17406F
}

/* .carousel-container-detail .swiper-slide {
    opacity: 0.8;
    filter: blur(3px);
} */

.carousel-container-detail .swiper-slide.swiper-slide-active {
    opacity: 1;
    filter: blur(0);
}

.carousel-container-detail .custom-next-detail,
.custom-prev-detail {
    background: rgba(255, 255, 255, 0.8);
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid #17406F;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
}
.carousel-container-detail .swiper-pagination {
    position: relative;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin-top: 25px;
}

.custom-prev-detail {
    left: 15px;
    z-index: 1;
}

.custom-next-detail {
    right: 15px;
    z-index: 1;
}

/* .swiper-pagination.swiper-pagination-clickable.swiper-pagination-bullets.swiper-pagination-horizontal {
    bottom: -15%;
} */


@media (max-width: 991px) {
    .carousel-item-detail {
        padding: 10px;

    }

    .fourth-image {
        padding: 10px;
    }
}

@media (max-width: 576px) {
    .carousel-item-detail {
        display: flex !important;
        align-items: center;
        padding: 8px;
        background-color: #fff;
        border-radius: 8px;
        transition: transform 0.3s ease-in-out;
        border: 1px solid #17406F;
        flex-direction: column-reverse;

    }

    .carousel-image-detail {
        width: 100%;
        /* height: 100%; */
        object-fit: cover;
        border-radius: 5px;
        /* max-height: 171px; */
        height: 52%;
    }



}