.allFooter {
  margin-top: 150px;
}
.footer {
  background-color: #fff;
  color: #000;
  font-size: 15px;
  font-family: interRegular;
  padding: 30px 60px;
  display: grid;
  grid-template-columns: repeat(5,1fr);
  justify-content: center;
  align-content: center;
  justify-items: start;
  font-weight: 400!important;
}
.logoFooter {
  padding-bottom: 15px;
}
.footer>:nth-child(5) {
  justify-self: end;
  margin-left: auto;
}
.title {
  font-size: 18px;
  padding-bottom: 10px;
  font-family: ralewayRegular;
  font-weight: 700;
  color: #17406f;
}
.description {
  width: 94%;
}
.description-ar {
  padding-right: 0;
  padding-left: 50px;
}
.description p {
  padding-top: 3px;
}
ul.service {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  padding-left: 25px;
}
ul.service li::before {
  content: url('/public/images/footer/Vector\ \(4\).svg');
  left: -1.5rem;
  top: 50%;
  transform: translateY(-50%);
  position: absolute;
}
ul.service-ar {
  list-style-image: url("/public/images/icons/arrow left footer.svg");
  margin-right: 14px;
}
ul.service li {
  padding-bottom: 7px;
  position: relative;
}
ul.UlContacter {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 120%;
}
ul.UlContacter li {
  padding-bottom: 8px;
}
.link {
  text-decoration: none;
  color: #000;
}
.aLink {
  text-decoration: none;
  color: #000;
}
.contacterImg {
  margin-right: 18px;
  width: 20px;
}
ul.UlContacter-ar li .global {
  margin-left: 16px;
  margin-right: -3px;
}
.UlContacter-ar .contacterImg {
  margin-right: 0;
  margin-left: 20px;
}
.LinkGlobal {
  text-decoration: underline;
}
.seconde {
  margin-right: 20px;
  margin-left: 38px;
}
.UlContacter-ar .seconde {
  margin-right: 38px;
  margin-left: 20px;
}
.partenaire {
  margin-right: 0;
  height: 45px;
  background-color: #fff;
  padding: 9px 12px 8px 0;
}
.partenaire-ar {
  margin-right: 0;
  margin-left: 16px;
}
.group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding-top: 10px;
}
.copyright {
  height: 40px;
  font-size: 15px;
  background-color: #fff;
  align-items: center;
  color: #17406f;
  display: flex;
  justify-content: center;
  font-family: interRegular;
  border-top: 1px solid #e4e4e4;
  text-align: center;
}
.copyright>bdi>span {
  padding-right: 10px;
}
.copyright>bdi>a {
  text-decoration: none;
  color: #17406f;
}
.droits-dauteur {
  padding: 0 12px;
}
.wp-google-right {
  display: flex;
  align-items: flex-end;
  gap: 20px;
  margin-top: 20px;
  padding: 5px;
}
.wp-google-powered {
  color: #17406f;
}
span.wp-google-rating {
  color: #17406f;
}
.grw-header {
  box-shadow: 0 0 5px rgba(23,64,111,.19);
  border-radius: 5px;
  width: fit-content;
}
@media (max-width:1204px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-content: center;
    justify-items: center;
  }
  .description {
    width: 100%!important;
  }
}
@media (min-width:1204px) {
  .notes {
    margin-left: 10%;
  }
  .services {
    margin-left: 10%;
  }
  .partenaires {
    margin-left: 5%;
  }
  .contacter {
    margin-left: -3%;
  }
}
@media (max-width:1204px) and (min-width:831px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(3,1fr);
    justify-content: center;
    align-content: center;
    justify-items: center;
  }
  .partenaires {
    grid-column: span 2!important;
    width: 100%;
  }
  .notes {
    grid-column: span 1!important;
    width: 100%;
  }
  .description {
    width: 100%!important;
  }
}
@media (max-width:830px) {
  .footer {
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-content: center;
    justify-items: start;
  }
  .description {
    width: 70%!important;
  }
  .contacter {
    width: 100%;
  }
}
@media (max-width:991px) {
  .allFooter {
    margin-top: 90px;
  }
}
@media (max-width:919px) {
  .contacter {
    padding-top: 30px;
  }
}
@media (min-width:770px) {
  .services {
    padding-top: 22px;
  }
  .contacter {
    padding-top: 22px;
  }
  .partenaires {
    padding-top: 22px;
  }
  .notes {
    padding-top: 22px;
  }
}
@media (min-width:769px) {
  .hideFooter {
    display: none;
  }
}
@media (max-width:768px) {
  .footer {
    flex-direction: column;
    display: flex;
  }
  ul.service li::before {
    left: -.5rem;
    top: 50%;
    transform: translateY(-10%);
    position: absolute;
  }
  hr.hideFooter {
    border-top: 1px solid #ccc!important;
    width: 100%;
    opacity: .4;
    margin: 10px 0;
  }
  ul.service {
    padding-left: 10px;
  }
  .hide {
    display: none!important;
    transition: all .3s cubic-bezier(.42, 0, 1, 1);
  }
  .show {
    display: block!important;
    transition: all 1s cubic-bezier(.83, .67, .17, .67)!important;
  }
  .buttonFooter {
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%!important;
  }
  .description {
    width: auto!important;
    padding: 0!important;
    padding-bottom: 15px!important;
  }
  .services {
    width: 100%!important;
  }
  .contacter {
    width: 100%!important;
    margin-right: 0!important;
  }
  .partenaires {
    width: 100%!important;
    flex-direction: row!important;
  }
  .title {
    padding: 0!important;
    padding-bottom: 0!important;
    margin: 0;
    font-size: 16px;
  }
  .service li {
    padding-left: 20px;
    padding-top: 10px;
    padding-bottom: 0!important;
  }
  .liImg {
    display: none;
  }
  .contacter {
    padding-top: 0;
  }
  .UlContacter li {
    padding-top: 10px;
    padding-bottom: 0!important;
  }
  .groupShow {
    padding-top: 15px!important;
  }
  .grouphide {
    padding: 0!important;
  }
}
@media (max-width:619px) {
  .partenaire {
    margin-top: 10px;
  }
}
@media (max-width:575px) {
  .allFooter {
    margin-top: 75px;
  }
  .title {
    font-size: 14px;
  }
  ul.service {
    font-size: 14px;
  }
  .description p {
    line-height: 26px!important;
    font-size: 14px!important;
  }
  .copyright {
    font-size: 13px;
  }
  .footer {
    padding: 15px 25px;
  }
  ul.UlContacter.show {
    font-size: 14px;
  }
  .partenaire {
    margin-top: 0;
    height: 45px;
  }
}