.faq-container {
  margin-top: 20px;
}

.faq-item {
  margin-bottom: 15px;
  border-radius: 6px;
  border: 1px solid #17406F;
}

.faq-question {
  padding: 20px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  line-height: 34px;
  gap: 20px;
}

.faq-answer {
  padding: 0px 20px 20px 20px;
}

.faq-icon {
  font-size: 18px;
  pointer-events: none;
}

.faq-icon.open {
  color: blue;
}

@media (max-width: 991px) {
  .faq-question {
    padding: 18px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 20px;
    line-height: 34px;
  }
  .faq-container {
    margin-top: 5px;
}
}

@media (max-width: 767px) {
  .faq-question {
    padding: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 18px;
    line-height: 31px;
  }

  .faq-icon svg {
    width: 32px;
    height: 32px;
  }

  .faq-container {
    margin-top: 0px;

  }
}

@media (max-width: 575px) {
  .faq-question {
    padding: 16px;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 16px;
    line-height: 27px;
  }
  .faq-icon svg {
    width: 28px;
    height: 28px;
  }
  .faq-container {
    margin-top: 10px;

  }
 
}