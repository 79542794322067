.Equipe-cm-container {
  margin-top: 70px;
  margin-bottom: 70px;
}

.services-section-media {
  display: flex;
  margin-top: 40px;
  flex-direction: row;
  align-items: stretch;
  width: calc(100vw - 20px);
  margin-left: calc(-50vw + 50% + 10px);
  gap: 20px;
}

.service-card-media {
  flex: 1;
  border: 1px solid #17406F;
  padding: 25px 25px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 20px;
}

.service-card-media:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  cursor: pointer;
}

.service-card-media:nth-child(2),
.service-card-media:nth-child(4) {
  top: 65px;
  position: relative;
}

.service-media-icon {
  width: 90px;
  height: 90px;
}

.services-section-media h3 {
  font-size: 26px !important;
  margin-bottom: 0;
  font-family: 'RalewayRegular';
  color: black;
}

.services-section-media p {
  font-size: 15px;
  color: #000;
  margin-bottom: 10px;
}

.services-section-media ul,
.why-manager ul {
  list-style: none;
  padding: 0;
}

.services-section-media li {
  font-size: 15px;
  text-align: left;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
}

.services-section-media li:before,
.why-manager li:before {
  content: url('/public/images/footer/Vector\ \(4\).svg');
  margin-right: 8px;
  margin-top: 3px;

}

.why-manager {
  margin-top: 140px;
}

.why-manager li {
  text-align: left;
  margin-bottom: 5px;
  display: flex;
  align-items: flex-start;
}

.why-p {
  font-size: 22px !important;
  line-height: 35px !important;
  color: #20ACE3;
  font-family: 'RalewayRegular';
  font-weight: 600;
  margin: 30px 0px;
}

.social-media-actions {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
}

.action-card {
  display: flex;
  align-items: center;
  border-radius: 6px;
  padding: 10px 15px;
  background-color: #fff;
  border: 1px solid #17406F;
  gap: 10px;
  min-height: 70px;
  cursor: pointer;
}


.icon {
  font-size: 30px;
  margin-right: 15px;
  color: #007bff;
}

.action-card p {
  font-size: 15px;
  margin: 0;
  line-height: 24px !important;
}

.other-services-media-page {
  margin-top: 70px;
}

.other-cards-media-page {
  display: flex;
  gap: 35px;
  margin-top: 15px;
}

.other-card-media {
  flex: 1;
  border: 1px solid #17406F;
  padding: 30px 30px;
  border-radius: 6px;
  text-align: left;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
  gap: 20px;
  cursor: pointer;
  text-decoration: none !important;
}

.other-card-media:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
}

.other-card-media h3 {
  font-size: 26px !important;
  line-height: 32px !important;
  font-family: 'RalewayRegular';
  color: #000;
  margin-bottom: 0px !important;
}

.meet-rec-cm {
  margin-top: 70px;
}

.show-more-btn {
  display: flex;
  padding: 10px 30px;
  font-size: 15px;
  color: #fff;
  background-color: #20ACE3;
  /* Bleu doux */
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
  margin: 0 auto;
  margin-top: 15px;
}

.show-more-btn:hover {
  background-color: #20ACE3;
  transform: translateY(-2px);
}

.show-more-btn:active {
  background-color: #20ACE3;
  transform: translateY(1px);
}


@media (max-width: 1200px) {
  .service-media-icon {
    width: 75px;
    height: 75px;
  }

  .other-card-media img {
    width: 75px;
    height: 75px;
  }

  .services-section-media h3 {
    font-size: 22px !important;
    line-height: 32px !important;
  }

  .service-card-media {
    padding: 20px 20px;
  }
}

@media (max-width: 991px) {
  .services-section-media {
    flex-wrap: wrap;
    margin-left: auto;
    margin-top: 25px;
    width: auto;
  }

  .service-card-media {
    width: 100%;
    flex-basis: calc(50% - 10px);
  }

  .service-card-media:nth-child(2),
  .service-card-media:nth-child(4) {
    top: 0px;
    position: unset;
  }

  .Equipe-cm-container {
    margin-top: 30px;
    margin-bottom: 0px;
  }

  .why-manager {
    margin-top: 60px;
  }

  .other-services-media-page {
    margin-top: 50px;
  }

  .services-section-media li {
    font-size: 14px;
    line-height: 22px;
  }

  .service-media-icon {
    width: 65px;
    height: 65px;
  }

  .other-card-media img {
    width: 65px;
    height: 65px;
  }

  .services-section-media h3 {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .other-card-media h3 {
    font-size: 24px !important;
    line-height: 26px !important;
  }

  .other-card-media {
    gap: 15px;
  }

  .other-cards-media-page {
    gap: 25px;
  }

  .why-p {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .meet-rec-cm {
    margin-top: 50px;
  }
}

@media (max-width: 767px) {
  .action-card {
    min-height: 94px;
  }

  .action-card p {
    font-size: 14px;
    margin: 0;
    line-height: 20px !important;
  }

  .other-card-media h3 {
    font-size: 22px !important;
    line-height: 22px !important;
  }

  .other-cards-media-page {
    margin-top: 0px;
  }

  .Equipe-cm-container {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .service-card-media {
    padding: 18px 18px;
  }

  .why-manager {
    margin-top: 46px;
  }

  .other-services-media-page {
    margin-top: 45px;
  }

  .why-p {
    font-size: 18px !important;
    line-height: 28px !important;
  }

  .meet-rec-cm {
    margin-top: 45px;
  }
}

@media (max-width: 575px) {
  .Equipe-cm-container {
    padding: 0 20px;
  }

  .why-manager {
    padding: 0 20px;
  }

  .other-services-media-page {
    padding: 0 20px;
  }

  .other-cards-media-page {
    margin-top: 10px;
  }

  .other-card-media h3 {
    font-size: 20px !important;
    line-height: 22px !important;
  }

  .action-card {
    padding: 10px 10px;
  }

  .other-card-media {
    padding: 18px 18px;
  }

  .social-media-actions {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 15px;
  }

  .other-cards-media-page {
    gap: 15px;
    flex-direction: column;
  }

  .Equipe-cm-container {
    margin-top: 5px;
    margin-bottom: 0px;
  }

  .why-manager ul {
    margin-top: 10px;
  }

  .why-p {
    font-size: 16px !important;
    line-height: 26px !important;
    margin: 20px 0px;
  }

  .service-card-media {
    width: 100%;
    flex-basis: 100%;
  }

  .services-section-media {
    margin-top: 15px;
  }

  .meet-rec-cm {
    margin-top: 35px;
  }

  .other-services-media-page {
    margin-top: 35px;
  }

  .why-manager {
    margin-top: 35px;
  }

}