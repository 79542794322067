.detail-blog {
    display: flex;
    gap: 20px;
  }
  
  .blog-detail-content {
    display: flex;
    flex-direction: row;
    width: 100%;
  }
  
.detail-blog .blog-post {
    flex: 3;
    width: 66%;
  }
  
.detail-blog .blog-image {
    width: 100%;
    height: auto;
    border-radius: 8px;
    margin-bottom: 20px;
  }
  .blog-content img{
    max-width: 100%;
    height: auto;
  }
  /* .blog-meta {
    position: absolute;
    bottom: 20px;
    border-top-right-radius: 5px; 
    border-bottom-left-radius: 5px; 
    background-color: #20ACE3; 
    color: #fff; 
    padding: 5px 15px;
    font-size: 14px; 
  } */
  
.detail-blog .blog-title {
  font-size: 24px !important;
  margin-bottom: 0px;
  line-height: 39px !important;
  margin-top: 12px;
  font-weight: 600;
}

.blog-meta .blog-date{
  font-size: 16px !important;
  color: #20ACE3;
  margin-bottom: 10px;
  line-height: 34px !important;
  margin-top: 10px;
}

  .blog-image-container {
    position: relative;
  }
  .recent-posts {
    padding-left: 20px;
    border: 1px solid #17406F;
    padding: 25px;
    border-radius: 6px;
    flex: 1.2;
    height: fit-content;
  }
  
  .detail-blog .search-bar input {
    width: 100%;
    padding: 16px;
    border-radius: 6px;
    border: 1px solid #D9D5D5;
  }
  
  .detail-blog .recent-post-item {
    display: flex;
    gap: 10px;

  }
  .recent-post-item a {
    display: flex;
    gap: 10px;
    text-decoration: none;
    font-family: 'RalewayRegular';
    color: #000;
    font-size: 18px;
    line-height: 28px;
    align-items: center;
  }

  .recent-posts ul{
    padding-left: 0px !important;
    margin-bottom: 0;
    gap: 25px;
    display: flex;
    flex-direction: column;
  }
  .detail-blog .recent-post-info {
    display: flex;
    flex-direction: column;
    
  }
  
  .detail-blog .recent-post-date {
    font-size: 14px;
    color: #20ACE3;
    font-family: 'InterRegular';
  }
  
 .recent-title{
   font-size: 20px !important;
   color: #000 !important;
   line-height: 37px !important;
  }
  .recent-post-title{
    font-size: 16px !important;
    font-family: 'RalewayRegular';
    font-weight: 400;
    line-height: 28px !important;
    color: #000;
    margin-bottom: 0;
    display: -webkit-box;
    line-clamp:3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  h3.question-blog {
    font-family: 'RalewayRegular';
    font-weight: 600;
    color: #000;
    font-size: 20px !important;
    line-height: 33px !important;
}
.blog-detail-content th, 
.blog-detail-content td {
  border: 1px solid; 
  padding: 5px; 
}
  @media(max-width : 1400px){
    .recent-posts {
      flex: 1.5;
    }
  }
  @media(max-width : 1199px){
    .recent-post-title{
      font-size: 16px !important;
      line-height: 26px !important;
    }
    .recent-posts {
      padding: 20px;
    }
    .detail-blog .search-bar input {
      padding: 13px;
    }
    .detail-blog .blog-title {
      font-size: 30px !important;
      line-height: 42px !important;
     }
  }
  
  @media(max-width: 991px){
    .blog-detail-content {
      display: flex;
      flex-direction: column;
      width: 100%;
      gap: 35px;
     }
     .detail-blog .blog-title {
      font-size: 28px !important;
      line-height: 42px !important;
      margin-bottom: 10px;
      margin-top: 10px;
      }
      .detail-blog .blog-post {
        width: 100%;
    }
  }
  @media (max-width: 767px) {
    .detail-blog .blog-title {
        font-size: 26px !important;
        line-height: 36px !important;
        margin-bottom: 10px;
        margin-top: 10px;
    }
   }
  @media(max-width: 575px){
    .detail-blog.blog-detail-content {
      padding: 0 20px;
    }
    .detail-blog .blog-title {
      font-size: 22px !important;
      line-height: 33px !important;
      margin-bottom: 6px;
      margin-top: 5px;
    }
  }