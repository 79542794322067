.slider-container {
    width: 100vw;
    position: relative;
    margin-top: 75px;
  }
/*   
  .slider {
    display: flex;
    white-space: nowrap;
    animation: scroll 15s linear infinite;
    font-size: 72px;
    font-weight: 600;
    color: #fff;
    text-shadow: 
        2px 2px 0px #20ACE3,  
        -2px 2px 0px #20ACE3, 
        2px -2px 0px #20ACE3, 
        -2px -2px 0px #20ACE3, 
        2px 0px 0px #20ACE3,   
        -2px 0px 0px #20ACE3,  
        0px 2px 0px #20ACE3,   
        0px -2px 0px #20ACE3;  
    font-family: 'Raleway';
    
  } */
 
  .slider span {
    padding-right: 3rem;
    flex-shrink: 0;
  }
   /* .development {
    border-radius: 5px;
    color: #20ace3;
    font-weight: 700!important;
    padding: 0 10px;
    text-shadow: none;
    -webkit-text-stroke: 0;
}  */
.slider {
    -webkit-animation: scroll 15s linear infinite;
    animation: scroll 15s linear infinite;
    color: #fff;
    display: flex;
    font-family: 'RalewayRegular';
    font-size: 72px;
    font-weight: 500;
    text-shadow: 0.5px 1px 0 #20ace3, -1px 1px 0 #20ace3, 1px -1px 0 #20ace3, -1px -1px 0 #20ace3, 1px 0 0 #20ace3, -1px 0 0 #20ace3, 0 1px 0 #20ace3, 0 -1px 0 #20ace3;
    white-space: nowrap;
    -webkit-text-stroke: 0px #ffffff;
    letter-spacing: .02em;
}
  
 .development {
      color: #20ACE3;
      text-shadow: 0px;
    padding: 0 10px;
    border-radius: 5px;
  } 
  
  /* Keyframe for the infinite scroll */
  @keyframes scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }
  
  .slider-content {
    display: flex;
  }
  @media(max-width: 1200px){
    .slider {
      display: flex;
      white-space: nowrap;
      animation: scroll 15s linear infinite;
      font-size: 70px;
      font-weight: 600;
    color: #fff;
    text-shadow: 2px 2px 0px #20ACE3, -2px 2px 0px #20ACE3, 2px -2px 0px #20ACE3, -2px -2px 0px #20ACE3, 2px 0px 0px #20ACE3, -2px 0px 0px #20ACE3, 0px 2px 0px #20ACE3, 0px -2px 0px #20ACE3;
    font-family: 'Raleway';
    }
  }
  @media(max-width: 991px){
    .slider-container {
  
      margin-top: 30px;
  }
  }
  @media(max-width: 576px){
  .slider {
    display: flex;
    white-space: nowrap;
    animation: scroll 15s linear infinite;
    font-size: 42px;
    text-shadow: 
    1px 1px 0px #20ACE3, 
    -1px 1px 0px #20ACE3, 
    1px -1px 0px #20ACE3, 
    -1px -1px 0px #20ACE3;
  }
  
  .slider-container {
    width: 100vw;
    position: relative;
    margin-top: 16px;
}
}