.carousel-item {
    display: flex !important;
    align-items: center;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    transition: transform 0.1s ease-in-out;
    border: 1px solid #17406F;
    height: 100%;
    min-height: 480px;
}

.swiper {
overflow:unset !important;
}

.carousel-image {
    width: 50%;
    height: 100%;
    object-fit: cover;
    border-radius: 5px;
 
}

.carousel-item .content {
    padding: 10px 20px;
    text-align: left;
    width: 50%;
}

.carousel-item h3 {
    color: #17406F;
    margin-bottom: 28px;
    font-size: 28px !important;
}

.carousel-item p {
    margin-bottom: 40px;
    font-size: 18px;
    line-height: 36px !important;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.projects-carousel .swiper-slide{
    height: auto !important;
}
.carousel-item a {
    text-decoration: none;
    padding: 12px 40px;
    background-color: #17406F;
    color: white;
    border-radius: 6px;
    transition: background-color 0.3s;
    font-size: 16px !important;
    font-family: 'InterRegular';
}
.carousel-item a:hover {
    background-color: #20ACE3;
}
.projects-carousel .swiper-slide {
    opacity: 0.8;
    filter: blur(3px);
}
.projects-carousel .swiper-slide.swiper-slide-active {
    opacity: 1;
    filter: blur(0);
}
.projects-carousel .custom-next, .custom-prev {
    background: rgba(255, 255, 255, 0.8);
    width: 50px;
    height: 50px;
    text-align: center;
    align-items: center;
    display: flex;
    justify-content: center;
    border: 1px solid #17406F;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    position: absolute;
    cursor: pointer;
}

/* .custom-prev, .custom-next {
    cursor: pointer;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    transition: background-color 0.3s ease; 
} */

.custom-prev {
    left: 15px;
    z-index: 1;
}
.custom-next {
    right: 15px;
    z-index: 1;
}

.carousel-container {
    position: relative; 
}
.carousel-container .swiper-pagination {
    position: relative;
    text-align: center;
    transition: 300ms opacity;
    transform: translate3d(0, 0, 0);
    z-index: 10;
    margin-top: 25px;
}
span.swiper-pagination-bullet.swiper-pagination-bullet-active {
    width: 14px;
    height: 14px;
    background: #17406F !important;
}
span.swiper-pagination-bullet{
    width: 14px;
    height: 14px;
    background: #fff;
    border: 0.5px solid #17406F
}
.carousel-portfolio-home-link{
    text-decoration: none !important; 
    color: inherit !important;       
    outline: none;     
}
@media (max-width: 1440px){
    .carousel-item{
     min-height: unset; 
    }
}

@media (max-width: 1200px){
    .carousel-item p {
        margin-bottom: 30px;
        font-size: 16px;
        line-height: 30px !important;
    }
    .carousel-item h3 {
        color: #17406F;
        margin-bottom: 14px;
        font-size: 24px !important;
    }
    .carousel-item a {
        font-size: 14px !important;
    }
}

@media (max-width: 576px){
    .carousel-item .content {
      
        text-align: left;
        width: 100%;
        

    }
    .carousel-item {
        display: flex !important;
        align-items: center;
        padding: 8px;
        background-color: #fff;
        border-radius: 8px;
        transition: transform 0.3s ease-in-out;
        border: 1px solid #17406F;
        height: 480px;
        flex-direction: column-reverse;
        height: 430px !important;
        justify-content: flex-end;
    }
    .carousel-image {
        width: 100%;
        object-fit: cover;
        border-radius: 5px;
        height: 55%;
    }
    .carousel-item h3 {
        color: #17406F;
        margin-bottom: 0px;
        font-size: 16px !important;
        line-height: 24px !important;
    }
    .carousel-item p {
        margin-bottom: 20px;
        font-size: 14px;
        line-height: 28px !important;
    }
    .carousel-item a {
        font-size: 13px !important;
        padding: 12px 35px;
    }
}