.service-status {
    text-align: center;
    margin-top: 60px;


  }
  
  .service-status h2 {
    font-size: 34px !important;
    margin-bottom: 8px;
    line-height: 48px !important;
    color: #17406F !important;
    text-align: center !important;

  }
  

  .status-cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    gap: 16px;
    margin-top: 50px;
  }
  
  .status-card {
    border: 1px solid #ccc;
    padding: 20px;
    border-radius: 8px;
    text-align: left;
    flex: 0 0 48%;
    position: relative;
  }
  
  .status-card h3 {
    font-size: 18px !important;
    margin: 0 0 10px;
    font-family: 'RalewayRegular';
    font-weight: 600;
  }
  
  .status-label {
    display: inline-block;
    padding: 4px 8px;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 400;

  }
  
  .green { background-color:  #73AF0078; }
  .blue { background-color: #C1ECFF;}
  .orange { background-color: #F29F00B5;}
  
  .check-status {
    position: absolute;
    bottom: 10px;
    right: 10px;
    font-size: 15px;
    color: #17406F;
    text-decoration: underline;
  }
.status-card img{
    padding-right: 6px;
}
@media (max-width: 991px){
    .status-card {
        border: 1px solid #ccc;
        padding: 20px;
        border-radius: 8px;
        text-align: left;
        flex: 0 0 100%;
        position: relative;
    }
    .status-cards {
        flex-direction: column;
    }
}
@media(max-width: 575px){
    .status-cards {
        margin-top: 27px;
        padding: 0 20px;
    }
    .service-status h2 {
        font-size: 30px !important;
        padding-left: 20px;
        text-align: left !important;
    }
    .status-card {
      padding: 17px;
    }
    .status-card h3 {
      font-size: 16px !important;
    }
    .check-status {
      font-size: 13px;
    }
    .status-label {
        font-size: 14px;
        padding: 4px 4px;
        margin-bottom: 15px;
    }
    .p-statut{
      padding: 0 20px;
      text-align: left;
    }
}
@media(max-width: 450px){
  .status-label {
    margin-bottom: 24px;
  }
  .check-status {
    font-size: 12px;
}
}