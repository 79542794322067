.capacity-container{
    margin-top: 80px;
}
.content-capacity{
    padding-left: 5.5rem;
}
.faq-ui-ux{
    margin-top: 80px;
}
.impact-table-container {
    margin-top: 70px;
  }
  
  .impact-table {
    width: 100%;
    border-collapse: separate; 
    border-spacing: 13px; 
    margin-top: 20px;
    border-radius: 10px; 
    overflow: hidden; 
    border: 1px solid #17406F; 
  }
  
  .impact-table th,
  .impact-table td {
    padding: 10px;
    border-bottom: 1px solid transparent; 
  }
  
  .impact-table th {
    text-align: left;
    font-family: 'RalewayRegular';
    color: #20ACE3;
    font-size: 26px;
    line-height: 32px;
    border-bottom: 1px solid #17406F; 
    background-color: white; 
  }
  
  .impact-table td {
    text-align: left;
    font-size: 18px;
    line-height: 35px;
    background-color: #EEF7FB; 

  }
  .impact-table td:first-child {
    border-left: none; 
  }
  .cta-container {
    margin-top: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    gap: 20px;
    align-items: center;
  }
  
  .cta-container p {
    margin-bottom: 10px;
    font-size: 22px;
    font-family: 'RalewayRegular';
    color: #17406F;
    font-weight: 500;
  }
  
  .cta-container button {
    padding: 14px 20px !important;
    background-color: #20ACE3;
    color: white;
    border: none;
    border-radius: 6px !important;
    cursor: pointer;
    font-size: 18px;
    position: relative;
    overflow: hidden;
    z-index: 1;
    transition: transform 0.3s ease-in-out;
    will-change: transform;
}

.cta-container button:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #17406F;
    z-index: 0;
    transition: left 0.3s ease-in-out;
    border-radius: 6px; 
}

.cta-container button:hover:before {
    left: 0;
}

.cta-container button:hover {
   
    color: white; 
}
.cta-container button span {
  position: relative;
  z-index: 2; 
}

  .impact-table th{
    padding-top: 0;
  }
@media(max-width: 991px){
  .faq-ui-ux {
    margin-top: 60px;
  }
  .impact-table-container {
    margin-top: 50px;
  }
  .capacity-container {
    margin-top: 24px;
  }
  .cta-container p {
    margin-bottom: 10px;
    font-size: 18px;
  }
  .cta-container button {
    font-size: 16px;
  }
  .content-capacity {
    padding-left: 0;
}
.impact-table{
  border-spacing: 10px;
  margin-top: 8px;
}
.impact-table th {
  padding-top: 0px;
  font-size: 22px;
}
}


  @media(max-width: 767px){
    .content-capacity {
        padding-left: 0;
    }
    .impact-table th {
      font-size: 22px;

      line-height: 22px;
    }
    .impact-table td {
      font-size: 16px;
      line-height: 25px;
    }
   
}
@media(max-width: 575px){
  .content-capacity {
    padding-right: 20px;
    padding-left: 20px;
  }
  .cta-container {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    gap: 0px;
    align-items: flex-start;
    padding: 0 20px;
  }
  .impact-table td {
    font-size: 14px;
    line-height: 24px;
  }
  .impact-table th {
    font-size: 18px;
    line-height: 28px;
  }
  .impact-container{
    padding: 0 20px;
  }
  .fq-design{
    padding: 0 20px;
  }
  .cta-container p {
    margin-bottom: 10px;
    font-size: 16px;
   }
   .cta-container button {
    font-size: 14px;
    padding: 14px 14px !important;
   
  }
  .faq-ui-ux {
    margin-top: 50px;
}
.capacity-container {
  margin-top: 20px;
}
.impact-table{
  border-spacing: 8px;
  margin-top: 5px;
}
}
