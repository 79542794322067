.prestation-page h1 {
  font-size: 42px !important;
  line-height: 45px;
  font-family: RalewayRegular !important;
  font-weight: 600;
  color: #20ACE3;
}

.prestation-page .btn {
  background-color: #20ACE3;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  box-shadow: none;

  border-radius: 6px;
}

.prestation-page .btn:hover {
  background-color: #007bb5;
}

.block-container {
  border: 1px solid #17406F;
  padding: 20px;
  margin: 37px auto;
  border-radius: 6px;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.block-container h3 {
  font-size: 24px !important;
  color: #20ACE3;
  font-weight: 400 !important;
}

ul.benefits-list {
  list-style-type: none;
  padding: 0;
}

ul.benefits-list li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  margin-left: 62px;
  margin-top: 25px;
  font-size: 18px;
  line-height: 28px;
}

ul.benefits-list li img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

ul.benefits-list li p {
  margin: 0;
}

.toggle-btn {
  background: none;
  border: none;
  cursor: pointer;
  padding: 0 !important;
}

.footer h4 {
  font-size: 1.1em;
  color: #0099cc;
}

.respect-container {
  display: flex;
  align-items: baseline;
}

.respect-icon {
  flex-shrink: 0;
  margin-right: 15px;
}
.respect-container:hover .respect-icon {
  transform: rotate(-50deg);
  transition: transform 0.5s ease-in-out; 
}

.respect-content h3 {
  font-size: 32px !important;
  line-height: 50px !important;
  font-family: 'RalewayRegular';
  font-weight: 600;
  color: #17406F;
  margin-bottom: 10px;
}

/* .respect-content p {
  font-size: 18px !important;
  line-height: 32px !important;
  margin-bottom: 20px;
} */

ul.respect-list {
  list-style-type: none;
  padding: 0;
  margin-top: 36px;
}

ul.respect-list li::before {
  content: url('../../../photos/Group\ 38978.svg');
  width: 15px;
  height: 14px;
  margin-top: 3px;
}

ul.respect-list li {
  display: flex;
  margin-bottom: 36px;
  justify-content: flex-start;
  gap: 13px;
  font-size: 18px;
  line-height: 29px;
}

ul.respect-list li div span {
  color: #20ACE3;
  font-weight: 600;
}



.rdv-container {
  border: 1px solid #17406F;
  border-radius: 6px;
  padding: 40px 40px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 40px;
  gap: 40px;


}

span.rdv-text {
  font-size: 22px;
  line-height: 42px;
  color: #17406F;
  font-family: 'RalewayRegular';
  font-weight: 500;
  width: 68%;
}

.advantages-risks-container {
  display: flex;
  justify-content: center;
  margin: 50px 0;
}

.advantages-risks-grid {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.advantages-risks-grid h2 {
  color: #20ACE3 !important;
  font-family: 'RalewayRegular';
  font-size: 34px !important;
  font-weight: 500;
}

.advantages-section,
.risks-section {
  width: 45%;
}

.prestation-page h2 {
  font-size: 1.5em;
  margin-bottom: 20px;
  color: #17406F;
}

.advantages-content,
.risks-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding-left: 13%
}

.advantage-item,
.risk-item {
  font-size: 18px;
  font-weight: 400;
  line-height: 28px;
  color: #000;
}

strong {
  color: #17406F;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
}

.img-risk {
  height: 54px;
  width: 57px;
}

.qualities-container {
  margin-top: 80px;
  display: flex;
  align-items: baseline;
}

.qualities-content h3 {
  font-size: 32px !important;
  line-height: 50px !important;
  font-family: 'RalewayRegular';
  font-weight: 600;
  color: #17406F;
  margin-bottom: 10px;
}

.service-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 20px;
  padding-left: 6%;
  margin-top: 20px;
  margin-bottom: 50px;
}

.service-item {
  text-align: left;
  padding-right: 20%;
}

.icon {
  margin-bottom: 10px;
}

.service-container h3 {
  color: #20ACE3;
  margin-bottom: 10px;
  font-size: 22px !important;
  font-family: 'RalewayRegular';
  font-weight: 600 !important;
  line-height: 34px !important;
}

.service-container p {
  font-size: 18px;
  color: #000;
  line-height: 32px;
}

.service-item.last-service-item {
  grid-column: 1 / -1;
  padding-right: 7%;
}

.rdv-container h4 {
  font-family: 'RalewayRegular';
  font-size: 28px !important;
  font-weight: 600;
  line-height: 45px !important;
  color: #17406F;
}

@media (max-width: 991px) {

  .respect-content h3,
  .qualities-content h3 {
    font-size: 26px !important;
    line-height: 35px !important;
  }

  span.rdv-text {
    font-size: 18px;
    line-height: 30px;
    color: #17406F;
    font-family: 'RalewayRegular';
    font-weight: 500;
    width: 60%;
  }
/* 
  .respect-content p {
    font-size: 16px !important;
    line-height: 28px !important;
    margin-bottom: 20px;
  } */

  ul.respect-list li {
    display: flex;
    margin-top: 12px;
    justify-content: flex-start;
    gap: 13px;
    font-size: 16px;
    line-height: 28px;
  }

  .block-container h3 {
    font-size: 22px !important;
    color: #20ACE3;
    font-weight: 400 !important;
  }

  ul.benefits-list li {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    margin-left: 62px;
    margin-top: 25px;
    font-size: 16px;
  }

  .advantage-item,
  .risk-item {
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: #000;
  }

  strong {
    color: #17406F;
    font-weight: 500;
    font-size: 18px;
    line-height: 26px;
  }

  .service-container h3 {
    margin-bottom: 10px;
    font-size: 18px !important;
    line-height: 26px !important;
  }

  .service-container p {
    font-size: 16px;
    line-height: 28px;
  }

  .rdv-container h4 {
    font-size: 26px !important;
    line-height: 39px !important;
  }

  ul.respect-list {
    margin-top: 26px;
  }

  .qualities-container {
    margin-top: 36px;
  }

  .service-container {
    margin-bottom: 36px;
    padding-left: 0;
  }

  .rdv-container {
    margin-top: 15px;
  }
  .respect-icon{
    display: none;
  }
}

@media (max-width: 767px) {
  .block-container h3 {
    font-size: 20px !important;
    color: #20ACE3;
    font-weight: 400 !important;
    line-height: 28px !important;
  }

  .block-container img {
    width: 58px;
    height: 54px;
  }

  .block-container svg {
    width: 34px;
    height: 34px;
  }

  .block-container {
    border: 1px solid #17406F;
    padding: 18px;
    margin: 20px auto;
  }

  .respect-content h3,
  .qualities-content h3 {
    font-size: 24px !important;
    line-height: 40px !important;
  }
  
  .service-container {
    padding-left: 0px;
    grid-template-columns: repeat(1, 1fr);

  }
  .rdv-container {
    margin-top: 20px;
    border: 1px solid #17406F;
    border-radius: 6px;
    padding: 25px 25px;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 40px;
    gap: 20px;
    flex-direction: column;
  }

  span.rdv-text {
    font-size: 18px;
    line-height: 30px;
    color: #17406F;
    font-family: 'RalewayRegular';
    font-weight: 500;
    width: 100%;
  }

  .advantages-risks-container {
    margin: 30px 0;
  }

  .advantages-risks-grid {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: center;
    gap: 20px;
  }

  .advantages-content,
  .risks-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
    padding-left: 13%;
    gap: 20px;
  }

  .advantages-section,
  .risks-section {
    width: 100%;
  }

  .advantages-risks-grid h2 {
    color: #20ACE3 !important;
    font-family: 'RalewayRegular';
    font-size: 32px !important;
    font-weight: 500;
    margin-bottom: 0px !important;
  }

  .qualities-container {
    margin-top: 20px !important;
  }

  .service-container h3 {
    font-size: 16px !important;
    line-height: 24px !important;
    margin-bottom: 0px;
  }
}

@media (max-width: 575px) {
  .title-p {
    font-size: 14px !important;
    line-height: 25px !important;
  }

  .block-container {
    margin: 20px 20px;
  }

  .block-container h3 {
    font-size: 16px !important;
    line-height: 22px !important;
  }

  .block-container img {
    width: 50px;
    height: 47px;
  }

  .block-container svg {
    width: 31px;
    height: 31px;
  }

  ul.benefits-list li {
    margin-bottom: 15px;
    margin-left: 62px;
    margin-top: 16px;
    font-size: 14px;
  }

  .respect {
    margin: 0px 20px;
  }

  .respect-content h3,
  .qualities-content h3 {
    font-size: 20px !important;
    line-height: 30px !important;
    margin-bottom: 5px;
  }
/* 
  .respect-content p {
    font-size: 14px !important;
    line-height: 25px !important;
    margin-bottom: 12px;
  } */

  ul.respect-list li {
    margin-top: 10px;
    gap: 12px;
    margin-bottom: 22px;
    font-size: 14px;
    line-height: 25px;
  }

  .respect-icon {
    margin-right: 5px;
  }

  .respect-icon img {
    width: 62px;
    height: 62px;
  }

  .rdv-container {
    margin-top: 0px;
    padding: 14px 14px;
    margin-right: 20px;
    margin-left: 20px;
  }

  .rdv-container h4 {
    font-size: 20px !important;
    line-height: 29px !important;
  }

  .rdv-container img {
    width: 67px;
    height: 66px;
  }

  span.rdv-text {
    font-size: 16px;
    line-height: 26px;
  }

  .advantages-risks-container {
    margin: 26px 20px;
  }

  .advantages-risks-grid h2 {
    font-size: 24px !important;
    font-weight: 500;
  }

  strong {
    font-size: 15px;

    line-height: 26px;
  }

  .advantage-item,
  .risk-item {
    font-size: 14px;
    line-height: 23px;
  }

  .advantages-content,
  .risks-content {
    gap: 12px;
    padding-left: 15%;
  }

  .service-container {
    display: flex;
    flex-direction: column;
    padding-left: 20px;
    margin-top: 10px;
    margin-bottom: 0px;
    gap: 0px;
  }

  .service-container p {
    font-size: 14px;
    line-height: 23px !important;
  }

  .img-risk {
    height: 36px;
    width: 40px;
  }

  .img-adv {
    height: 39px;
    width: 40px;
  }

  .icon {
    margin-bottom: 5px;
  }

  .qualities-container {
    margin: 0px 20px;
  }
}