.service-card {
  padding: 20px;
    text-align: left;
    background-color: #fff;
    border: 1px solid #17406F;
    border-radius: 6px;
    height: 100%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    justify-content: center;
    height: 372px;
    gap: 35px;
}
.carousel-services-container {
  margin-top: 40px;
  width: calc(100vw - 20px);
  margin-left: calc(-50vw + 50% + 10px);
}
.margin-top{
  margin-top: 60px;
}
.carousel-services-container .swiper-slide.swiper-slide-next{
  margin-top: 60px;
}
.carousel-services-container .swiper-pagination {
  position: relative;
  text-align: center;
  transition: 300ms opacity;
  transform: translate3d(0, 0, 0);
  z-index: 10;
  margin-top: 25px;
}
.react-multi-carousel-item:last-child {
  margin-right: 50px;
}
.react-multi-carousel-dot button{
  opacity: 0;
}
.react-multi-carousel-dot {
  width: 16px !important;
  height: 16px;
  border-radius: 50% !important;
  padding: 0;
  box-shadow: none;
  border-width: 2px;
  border-style: solid;
  border-color: #17406F;
  margin-right: 6px;
  outline: 0;
  cursor: pointer;
  padding: 0px !important;
}
li.react-multi-carousel-dot.react-multi-carousel-dot--active {
  background-color: #17406F; 
  border-color: #17406F;
}

.react-multi-carousel-dot-list {
  position: absolute;
  bottom: -60px;; 
  left: 50%;    
  transform: translateX(-50%); 
  z-index: 1;    
}
.react-multi-carousel-list {
  overflow: unset!important;
}
.service-card h3 {
  font-size: 28px !important;
  margin-bottom: 15px;
  color: #000;
  font-weight: 600;
  font-family: 'Raleway';
  position: relative;
  display: inline-block;
}
.service-card:hover h3 {
  color: #20ACE3; 
}

.service-card h3::after {
  content: "";
  position: absolute;
  bottom: 0; 
  left: 0;
  width: 0; 
  height: 2px; 
  background-color: #20ACE3; 
  transition: width 0.5s ease-in-out; 
}

.service-card:hover h3::after {
  width: 100%; 
}
.service-card p {
  font-size: 15px;
  line-height: 30px !important;
}
.slick-slide:not(.slick-cloned):nth-of-type(2) .service-card {
  margin-top: 30px !important; 
}
.carousel-service-icon img,
.carousel-service-icon svg{
  width: 92px;
  height: 92px;
}
.carousel-services-container .swiper-pagination-bullet {
  width: 16px; 
  height: 16px; 
}
.carousel-services-container .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next{
  transform: scale(0.9);
}
.carousel-services-container .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-next-next{
  transform: scale(0);
}
.carousel-services-container .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev-prev{
  transform: scale(0);
}
.carousel-services-container .swiper-pagination-bullets-dynamic .swiper-pagination-bullet-active-prev{
  transform: scale(0.9);
}

.carousel-services-container .swiper-pagination-bullet-active {
  background-color: #004d80; 
}
.carousel-services-container a {
  text-decoration: none; 
  color: inherit;
}

@media (max-width: 1440px) {
  .slick-slider {
    margin-top: 34px;
  }
}
@media (max-width: 1439px){
  .carousel-service-icon img,
  .carousel-service-icon svg{
    width: 83px;
  }
}
@media (max-width: 1200px) {
.service-card p {
  font-size: 14px;
  line-height: 28px !important;
}
}
@media (max-width: 1023px) {
  .carousel-services-container .swiper-slide.swiper-slide-next{
    margin-top: 0px;
  }
  }
@media (max-width: 991px){
  .slick-slider {
    margin-top: 13px;
  }
}
@media(max-width: 767px){
  .carousel-services-container {
    margin-top: 14px !important;
  }
}
@media(max-width: 599px){
.slick-slider {
  margin-top: -5px;
}
.slick-dots {
  position: absolute;
  bottom: 22px;
}
.service-card {
  padding: 20px;
  text-align: left;
  background-color: #fff;
  border: 1px solid #17406F;
  border-radius: 6px;
  height: 80%;
  min-height: 348px;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  justify-content: space-around;
}
}
@media(max-width: 575px){
  .carousel-service-icon img,
  .carousel-service-icon svg{
    width: 60px;
  }
  .carousel-services-container {
    width: auto;
    margin-left: 0;
    margin: 0 20px;
}
  .service-card {
    justify-content: center;
    min-height: 308px;
    gap: 20px;
  }
  .service-card h3 {
    font-size: 24px !important;
    margin-bottom: 5px;
  }
 }