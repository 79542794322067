.consulting-page h1{
    font-size: 42px !important;
    line-height: 45px;
    font-family: RalewayRegular !important;
    font-weight: 600;
    color: #20ACE3;
}
.title-p{
    font-size: 18px !important;
    font-family: InterRegular;
    font-weight: 400;
    line-height: 35px !important;
    color: #000;
    margin-bottom: 0;
}
.consulting-cards {
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    align-content: center;
    flex-direction: row;
    align-items: stretch;
  }
  

  .consulting-card {
    width: 32%;
    border: 1px solid #17406F;
    padding: 30px 30px;
    border-radius: 6px;
    text-align: left;
  }
  .consulting-card:hover{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    cursor: pointer;
  }
  .consulting-card-icon {
    margin-bottom: 15px;
  }
  
  .consulting-card-title {
    font-size: 26px !important;
    margin-bottom: 15px;
    font-family: 'RalewayRegular';
    font-weight: 400;
    color: #000;
    line-height: 42px !important;
}
  .cardc2{
    position: relative;
    top: 65px; 
  }
  .consulting-card-text {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .consulting-services {
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 142px;
  }
  
  .service-consulting {
    display: flex;
    align-items: baseline;
    padding: 20px 0px;
    
  }
  .service-consulting:hover img{
    transform: rotate(-50deg);
    transition: transform 0.5s ease-in-out;
  }
  
  .service-icon {
    flex-shrink: 0;
    margin-right: 15px;
  }

  
  .service-content h3 {
    font-size: 32px !important;
    line-height: 50px !important;
    font-family: 'RalewayRegular';
    font-weight: 600;
    color: #17406F;
    margin-bottom: 10px;
  }
  
  .service-content p {
    font-size: 18px !important;
    line-height: 32px !important;
    margin-bottom: 20px;
  }
@media(max-width: 1399px){
  .consulting-card-title {
    font-size: 24px !important;
    line-height: 35px !important;
}
}
  @media (max-width: 991px) {
    .consulting-card-title {
      font-size: 20px !important;
      line-height: 31px !important;
      margin-bottom: 8px;

    }
    .consulting-card-icon img {
      height: 70px;
     }
    .consulting-card-text {
        font-size: 14px !important;
        line-height: 26px !important;
    }
    .consulting-card {
        padding: 18px 20px;
    }
    .service-content h3 {
      font-size: 26px !important; 
        line-height: 43px !important;
    }
    .service-content p {
        font-size: 16px !important;
        line-height: 28px !important;
    }
    .title-p {
        font-size: 16px !important;
        font-family: InterRegular;
        font-weight: 400;
        line-height: 28px !important;
    }
    .consulting-cards {
      margin-top: 25px;
    }
    .service-icon{
      display: none;
    }
  }

  @media (max-width: 767px) {
    .consulting-cards {
      flex-direction: column;
      margin-top: 20px;
    }
    .cardc2 {
        position: unset;
    }
  
    .consulting-card {
      width: 100%;
      margin-bottom: 20px;
    }
    .services {
        flex-direction: column;
      }
      .service-consulting {
        align-items: flex-start;
      }
    
      .service-icon {
        margin-bottom: 20px;
      }
      .consulting-services {
        margin-top: 20px;
      }
      .service-content p {
        font-size: 14px !important;
        line-height: 26px !important;
    }
    .service-content h3 {
        font-size: 24px !important;
        line-height: 37px !important;
    }
   
  }
  @media (max-width: 575px) {
    .consulting-services {
        margin-top: 0px;
        padding-left: 20px;
        padding-right: 20px;
        gap: 0px;
    }
    .consulting-cards {
        padding-left: 20px;
        padding-right: 20px;
        margin-top: 10px;
    }
    .service-content .btn {
        font-size: 14px;
    }
    .service-content h3 {
        font-size: 22px !important;
        line-height: 30px !important;
    }
    .consulting-card-icon img{
      height: 60px;
    }
    .service-consulting {
      padding: 18px 0px;
  }
}