.process-steps {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 40px;
    margin-top: 20px;
    width: 95%;
    padding-left: 5rem;
}
.choisir-notre-agence {
    margin-top: 85px;
    margin-bottom: 83px;
}
.step-container {
    display: flex;
    width: 80%;
    padding: 20px 25px;
    border-radius: 6px;
    position: relative;
    border: 1px solid #17406F;
    background-color: white;
    z-index: 0;
    min-height: 182px;
}

.step-container.left-step {
    grid-column: 1;
}

.step-container.right-step {
    grid-column: 2;
}

.step-number {
    width: 50px;
    height: 49px;
    color: #17406F;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 27px;
    font-family: 'RalewayRegular';
    font-weight: bold;
    position: absolute;
    border: 1px solid #17406F;
    z-index: 10;
    background-color: white;
}

.step-container.left-step .step-number {
    right: -2rem;
}

.step-container.right-step .step-number {
    left: -2rem;
    top: 8rem;
}

.step-details h3 {
    margin: 0;
    font-size: 20px !important;
    color: #20ACE3;
}
.step-container.left-step::after {
    top: 9%;
    right: -102px;
    content: "";
    position: absolute;
    width: 2px;
    height: 95%;
    background-color: #17406F;
    z-index: -1;
    transform: rotate(-56deg);
}

.step-container.right-step::after {
    bottom: -65%;
    left: -96px;
    content: "";
    position: absolute;
    width: 2px;
    height: 95%;
    background-color: #17406F;
    z-index: -1;
    transform: rotate(51deg);
}

.step-container:last-child::after {
    display: none;
}

.web-dev-cards {
    display: flex;
    margin-top: 40px;
    flex-direction: row;
    align-items: stretch;
    width: 100vw;
    margin-left: calc(-50vw + 50%);
    gap: 20px;
  }
  .web-dev-card {
    width: 32%;
    border: 1px solid #17406F;
    padding: 30px 30px;
    border-radius: 6px;
    text-align: left;
    transition: ttransform 0.3s ease, box-shadow 0.3s ease;
    cursor: pointer;
  }
  .web-dev-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    cursor: pointer;
  }
  .web-dev-card-icon {
    margin-bottom: 35px;
  }
  .web-dev-card-title {
    font-size: 26px !important;
    margin-bottom: 15px;
    font-weight: 500;
    color: #17406F;
    line-height: 42px !important;
}
.cardc2-web-dev{
    position: relative;
    top: 65px; 
  }
  .web-dev-card-text {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .questions-web{
    margin-top: 165px;
  }
.p-dev {
    font-size: 24px !important;
    line-height: 50px !important;
    max-width: 977px;
    margin-top: 18px;
}
.agence-cards-web {
    display: flex;
    margin-top: 40px;
    flex-direction: row;
    align-items: stretch;
    width: calc(100vw - 20px);
    margin-left: calc(-50vw + 50% + 10px);
    gap: 20px;
  }
  .carousel-page-web{
    margin-top: 66px;
  }
@media (max-width: 1399px) {
    .step-container.right-step .step-number {
        left: -2rem;
        top: 11.5rem;
    }
    .step-container {
        min-height: 256px;
    }
    .step-container.left-step::after {
        top: 14%;
        right: -92px;
        transform: rotate(-38deg);
    }
    .web-dev-card {
        flex: 1 1 calc(50% - 20px);
    }
    .cardc2-web-dev {
     position: unset ;
    }
    .agence-cards-web {
        flex-wrap: wrap;
    }
    .questions-web {
        margin-top: 85px;
    }

}
@media (max-width: 1199px) {
    .step-container.right-step .step-number {
        left: -2rem;
        top: 12.5rem;
    }
    .step-container {
        min-height: 239px;
    }
    .step-container.right-step::after {
        bottom: -68%;
        left: -96px;
        content: "";
        position: absolute;
        width: 2px;
        height: 95%;
        background-color: #17406F;
        z-index: -1;
        transform: rotate(41deg);
    }
    .step-container.left-step::after {
        top: 4%;
        right: -63px;
        transform: rotate(-31deg);
    }

}
@media (max-width: 991px){
    .web-dev-card-title {
        font-size: 22px !important;
        line-height: 32px !important;
    }
    .web-dev-card-text {
        font-size: 16px !important;
        line-height: 27px !important;
    }
    .choisir-notre-agence {
        margin-top: 50px;
    }
    .carousel-page-web {
        margin-top: 0px;
    }

    
    .web-dev-card {
        padding: 18px 20px;
    }
    .web-dev-cards {
      flex-direction: column;
      margin-top: 20px;
    }
    .cardc2-web-dev {
      position: unset;
  }
  
    .web-dev-card {
      width: 100%;
     
    }
    .agence-cards-web {
        margin-top: 0px;
        flex-wrap: wrap;
        width: auto;
        margin-left: auto;
      }
    .step-details h3 {
        margin: 0;
        font-size: 18px !important;
        color: #20ACE3;
        line-height: 28px !important;
        margin-bottom: 8px !important;
    }
    .step-container.right-step::after {
        display: none;
    }
    .step-container.left-step::after {
        display: none;
    }
    .process-steps {
        position: relative;
        display: grid;
        grid-template-columns: 1fr 1fr;
        gap: 40px;
        grid-template-columns: 1fr;
        margin-top: 5px;
        width: 100%;
        padding-left: 5rem;
    }
    .step-container {
        display: flex;
        width: 100%;
    }
    .step-container.right-step {
        grid-column: 1;
    }
    .step-container.left-step .step-number {
        left: -0.5rem;
        transform: translate(-50%, -50%);
        top: 50%;
    }
    .step-container.right-step .step-number {
        left: -0.5rem;
        transform: translate(-50%, -50%);
        top: 50%;
    }
    .step-container {
        min-height: auto;
    }
    .p-dev {
        font-size: 22px !important;
        line-height: 38px !important;
    }
    .questions-web {
        margin-top: 60px;
    }
    .title-dev-web.d-flex.justify-content-between.align-items-center {
        flex-wrap: wrap;
        gap: 10px;
    }
    .faq-container {
        margin-top: 5px;
    }
  
}
@media (max-width: 767px) {
    .process-steps {
        padding-left: 2rem;
    }
    .p-dev {
        font-size: 20px !important;
        line-height: 33px !important;
    }
    .process-steps {
        padding-left: 2rem;
        
    }
    .choisir-notre-agence {
        
        margin-bottom: 60px;
    }
    .questions-web {
        margin-top: 53px;
    }
    .step-details{
        padding-left: 10px;
    }
    .step-details h3 {
        margin-bottom: 8px !important;
    }
 
}
@media(max-width: 630px){
    .web-dev-card {
        flex-grow: 1;
        flex-shrink: 1;
        flex-basis: unset; 
    }
}
@media (max-width: 575px){
    .web-dev-cards {
        margin-top: 14px;
      }
      
      .web-dev-card {
        margin-bottom:0px;
      }
      .web-dev-card-title {
        font-size: 20px !important;
        line-height: 30px !important;
        margin-bottom: 5px;
       }
       .web-dev-card-text {
        font-size: 14px !important;
        line-height: 26px !important;
        margin: 0;
        }
        .choisir-notre-agence{
          padding: 0px 20px;
        }
        .site-sur-mesure {
            padding: 0px 20px;
        }
        .questions-web{
            padding: 0px 20px;
        }
        .web-dev-card-icon img {
            width: 55px;
        }
        .web-dev-card-icon {
            margin-bottom: 15px;
        }
        .p-dev {
            font-size: 18px !important;
            line-height: 31px !important;
        }
        .questions-web {
            margin-top: 40px;
        }
        .agence-cards-web {
           width: auto;
           margin-left: auto;
        }
        .process-steps {
            gap: 24px;
        }
        .choisir-notre-agence {
            margin-top: 40px;
            margin-bottom: 50px;
        }
        .carousel-page-web{
            padding: 0 20px;
        }
        .agence-cards-web {
            margin-top: 0;
        }
}