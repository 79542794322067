.navbar {
  z-index: 100;
  top: 0;
  background-color: #fff;
  width: 100%;
}
.nav-logo {
  padding: 0;
}
.link-demo {
  padding-bottom: 18px;
}
.langue>.dropdown-menu {
  min-width: 5rem!important;
}
.btn-check:focus+.btn-primary,.btn-primary:focus {
  color: #fff;
  background-color: #20ace3;
  box-shadow: none;
}
.dropdown-item:focus,.dropdown-item:hover {
  color: #20ace3;
  background-color: transparent;
}
.dropdown-item,.nav-link {
  color: #000;
  font-weight: 500;
}
.langue>.dropdown-item {
  padding: .25rem .5rem!important;
}
.dropdown-toggle:hover {
  color: #20ace3!important;
}
a.dropdown-toggle.nav-link:hover {
  color: #20ace3!important;
}
.title_without_dropdown:hover {
  color: #20ace3!important;
}
.dropdown-item.active,.dropdown-item:active {
  color: #20ace3!important;
  text-decoration: none;
  background-color: #fff!important;
}
.dropdown-toggle::after {
  background: url(/public/images/navbar/down-arrow-svgrepo-com.svg);
  content: ".";
  color: transparent;
  background-repeat: no-repeat;
  width: 22px;
  position: relative;
  top: 10px;
  left: 5px;
  right: 5px;
  display: none!important;
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 32 32' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='rgb(4,62,112, 1)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 8h24M4 16h24M4 24h24'/%3E%3C/svg%3E");
  width: 38px;
  margin-right: -10px;
  margin-bottom: 0;
}
.navbar-toggler>.close {
  display: inline;
  margin-right: -5px!important;
  position: relative;
  top: 0!important;
}
.navbar-toggler.collapsed>.close,.navbar-toggler:not(.collapsed)>.navbar-toggler-icon {
  display: none;
}
.navbar-toggler-icon:focus,.navbar-toggler:active,.navbar-toggler:focus {
  outline: 0;
  box-shadow: none;
}
.navbar-light .navbar-toggler {
  border-color: transparent;
}
.nav-services {
  display: flex;
  white-space: nowrap;
  width: auto;
  padding-left: 8px;
  padding-right: 0!important;
}
.nav-services .nav-link {
  padding-right: 0;
}
.navbar-nav {
  margin-left: auto;
}
@media (max-width:592px) {
  .navbar {
    padding: 2px 25px 0 35px!important;
  }
}
@media (max-width:1000px) and (min-width:992px) {
  .navbar-nav {
    gap: 10px;
  }
}
@media (max-width:1217px) {
  .navbar-nav {
    margin-right: 0;
    margin-left: 0;
  }
  .button {
    padding: 10px 20px 10px 20px!important;
  }
}
@media (max-width:1195px) {
  .button {
    padding: 10px 15px 10px 15px!important;
  }
}
@media (min-width:992px) {
  .buttonNav {
    margin-top: 23px!important;
  }
  .navbar-collapse {
    place-content: end;
  }
  .navbar-nav {
    padding-left: auto!important;
  }
  .menu {
    padding: 50px;
    padding-top: 25px;
  }
  .dropdown-toggle {
    padding: 0;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 12px!important;
    padding-right: 12px;
  }
  .with_dropdown {
    margin-top: 17px;
    max-height: 61px;
  }
  .without-dropdown {
    margin-top: 17px;
    max-height: 71px;
    padding: 25px 0 50px;
  }
  .title_without_dropdown {
    padding-left: 12px;
    padding-right: 12px;
  }
}
.menu {
  padding-right: 15px;
  padding-left: 15px;
}
.line {
  border-bottom: 1px solid #e4e4e4;
  width: 93%!important;
  margin: 0 auto;
  padding-top: 20px;
}
.langue>a {
  padding-left: 0!important;
  padding-right: 0!important;
  width: 44.6px;
}
@media (min-width:993px) {
  .navbar {
    padding: 10px 60px 0;
  }
  .nav-item.dropdown:hover .dropdown-menu.show {
    display: block;
    margin-top: -5px;
  }
  .dropdown-toggle {
    z-index: 10;
  }
  .dropdown-menu {
    top: 60px!important;
    border: none;
    box-shadow: 0 0 8px rgba(23,64,111,.19);
    border-radius: 5px!important;
  }
  .buttonNav {
    position: relative!important;
  }
  .dropdown-menu.show .nav-link {
    padding-bottom: 8px;
  }
}
@media (max-width:1400px) {
  .menu {
    padding-right: 10px;
    padding-left: 10px;
  }
  .langue {
    padding-left: 5;
  }
}
@media (max-width:1360px) {
  .menu {
    padding-right: 5px;
    padding-left: 5px;
  }
  .langue {
    padding-left: 5;
  }
}
@media (max-width:1340px) {
  .menu {
    padding-right: 0;
    padding-left: 0;
  }
  .langue {
    padding-left: 5;
  }
  .navbar-nav .nav-link {
    padding-left: 0;
  }
}
@media (max-width:1300px) {
  .navbar-nav .d-flex .nav-link {
    padding-left: 0;
  }
  .langue {
    padding-left: 5px;
  }
}
@media (max-width:1270px) {
  .menu:nth-child(5) {
    padding-right: 0;
  }
}
@media (max-width:1245px) and (min-width:992px) {
  .dropdown-toggle::after {
    left: -4px;
  }
  .title_without_dropdown {
    padding-left: 10px;
    padding-right: 10px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 10px!important;
    padding-right: 10px;
  }
  .menu:nth-child(5) {
    padding-right: 10px!important;
  }
}
@media (max-width:1190px) and (min-width:992px) {
  .navbar {
    padding: 22px 30px 0 30px;
  }
  .menu {
    padding-right: 0;
    padding-left: 0;
  }
}
@media (max-width:1099px) {
  .menu {
    padding-right: 6px;
    padding-left: 6px;
  }
  .title_without_dropdown {
    padding-right: 6px;
    padding-left: 6px;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-left: 6px!important;
    padding-right: 6px;
  }
  .menu:nth-child(5) {
    padding-right: 6px;
  }
}
@media (max-width:1060px) {
  .title_without_dropdown {
    padding-right: 4px;
    padding-left: 4px;
  }
}
@media (max-width:1060px) {
  .logo {
    padding-top: 5px;
  }
  .menu {
    font-size: 17px;
  }
}
@media (max-width:1024px) {
  .title_without_dropdown {
    padding-right: 2px;
    padding-left: 2px;
  }
}
@media (max-width:1000px) and (min-width:992px) {
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 2px;
    padding-left: 2px!important;
  }
}
@media (max-width:1015px) {
  .menu:nth-child(5) .title_without_dropdown {
    padding-right: 0;
  }
}
@media (max-width:991px) {
  .navbar {
    background-color: #fff;
    padding: 0 20px 5px 20px;
    height: 67px;
  }
  .navbar-ar {
    padding: 0 50px 5px 20px;
  }
  .dropdown-toggle::after {
    transition: transform 750ms;
    transform: rotate(-90deg);
  }
  .show>.dropdown-toggle::after {
    transform: rotate(0);
  }
  .div-langue {
    justify-content: space-between;
  }
  .logo {
    padding-top: 5px;
  }
  .cl-ln {
    flex-direction: column-reverse!important;
  }
  .navbar-collapse {
    position: absolute;
    top: 70px;
    right: 0;
    width: 100%;
    height: calc(100vh - 67px);
    background-color: #fff;
    z-index: 1050;
    overflow-y: auto;
    padding: 20px;
  }
  .navbar-nav {
    display: flex;
    justify-content: center;
    align-items: flex-start!important;
    gap: 0!important;
  }
  .navbar-ar .navbar-collapse {
    padding: 0 60px 0 50px;
  }
  .button {
    margin-bottom: 20px;
  }
  .nav-item.dropdown .dropdown-menu {
    top: 45px;
    border: 0;
    padding: 10px 20px 0 0;
    width: auto!important;
    box-shadow: none!important;
  }
  .navbar-nav hr {
    display: none;
  }
  .nav-item.dropdown .dropdown-menu .dropdown-item {
    border-top: 1px dashed #cecece;
    padding: 10px 0 10px 10px;
  }
  .menu_mobile-ar .dropdown-menu .dropdown-item {
    text-align: right;
  }
  .nav-services {
    padding-top: 8px;
    padding-left: 16px;
    display: flex;
    border-top: 1px dashed #cecece;
  }
  .servises-mobile .dropdown-menu {
    margin-left: -120px;
  }
  .menu {
    padding: 10px 0 10px 0;
    border-top: 1px dashed #cecece;
    width: 100%;
  }
  .menu .nav-link:first-child {
    padding: 0;
  }
  .dropdown-toggle::after {
    position: absolute;
    width: 30px;
    top: 6px;
    right: 0;
    left: auto;
  }
  .langue-ar .dropdown-toggle::after {
    margin-left: 0;
    right: auto;
    left: 0!important;
  }
  .menu_mobile-ar .dropdown-toggle::after {
    right: auto;
    left: 0;
  }
  .dropdown-toggle:focus {
    color: #20ace3!important;
  }
  @keyframes test {
    from {
      opacity: 90%;
      transform: translateX(40px);
    }
    to {
      opacity: 100%;
      transform: translateX(0);
    }
  }
}
@media (max-width:592px) {
  .navbar {
    padding: 0 6px 5px 20px!important;
  }
  .navbar-ar {
    padding: 0 30px 5px 6px!important;
  }
  .mx-2.close>img {
    width: 22px;
  }
  .dropdown-toggle::after {
    width: 26px!important;
  }
}
.backgroundSizeAndColor {
  background-color: #fff;
  height: 70px;
}
.logoBackground {
  padding-top: 10px;
  width: 110px!important;
}
.dropdown-menu.show {
  margin-top: 0!important;
}
.menu {
  font-weight: 500;
  font-size: 17px;
  transition: none!important;
  font-family: InterRegular;
}
a.dropdown-toggle.nav-link:hover svg path {
  fill: #20ACE3;
  stroke: #20ACE3;
}
.menu:hover {
  color: #20ace3;
  transition: none;
}
.logo {
  width: 152px;
}
.button {
  font-size: 18px;
  background-color: #0ca3fc;
  border: none;
  will-change: transform;
  transition: .3s ease-in-out;
  transition-delay: .35s;
  overflow: hidden;
  display: inline-block;
  border-radius: 6px!important;
  padding: 10px 30px;
  position: relative;
  color: #fff;
  text-align: center;
  z-index: 1;
}
.btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #17406f;
  transition: left .3s ease-in-out;
  z-index: 0;
  border-radius: 6px;
}
.button:hover:before {
  left: 0;
}
.button:hover {
  color: #fff;
}
.pseudo-text {
  display: inline;
  position: relative;
  font-weight: 500;
  transition: .25s ease-in;
  transition-delay: .1s;
  font-family: InterRegular;
  font-size: 17px;
}
.button:hover .pseudo-text {
  color: #fff;
}
.button:hover {
  background-color: #20ace3;
}
.navbar-light .navbar-nav .nav-link {
  color: #17406f;
}
.menus {
  height: 34px;
}
.navbar-light .navbar-nav .nav-link:focus {
  color: #17406f;
}
.navbar-light .navbar-nav .nav-link:hover {
  color: #17406f;
}
.navbar-light .navbar-nav .nav-link,.navbar-light .navbar-nav .show>.nav-link {
  color: #000;
}
.navbar-light .navbar-nav .show>.nav-link:hover {
  color: #17406f;
}
.servises-web.nav-item {
  text-decoration: none;
  padding-top: 0;
}
.servises-web .nav-link {
  padding: 4px 15px 4px 0!important;
}
.servises-web {
  padding-right: 80px;
}
.servises-web .dropdown-menu {
  margin-left: 93px;
  margin-top: -43px!important;
}
.servises-mobile.nav-item {
  text-decoration: none;
  padding-top: 0;
}
.servises-mobile .nav-link {
  padding: 4px 15px 4px 7px!important;
}
.servises-mobile .dropdown-menu {
  margin-top: -7px!important;
}