.soon-container{
    display: flex;
    align-items: center;
    justify-content: center;
    justify-content: center;
    gap: 40px;
    padding: 150px 15% 30px;
}

@media(max-width: 1199px){
    .soon-container img{
         width: 56%;
    } 
    
}
@media(max-width: 767px){
    .soon-container{
        gap: 20px;
    }
} 