.blog-page {
    padding: 20px;
    font-family: Arial, sans-serif;
  }
  
  .page-title {
    font-size: 24px;
    color: #0073e6;
    margin-bottom: 20px;
  }
  
  .search-bar {
    margin-bottom: 20px;
    text-align: right;
  }
  .search-input-container {
    position: relative;
    width: 100%;
  }
  .search-bar input {
    padding: 15px;
    border: 1px solid #D9D5D5;
    border-radius: 6px;

  }
  .search-input-container .search-icon {
    position: absolute;
    top: 50%; 
    right: 11px;
    transform: translateY(-50%);
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
  .blog-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
  }
  @media(max-width: 991px){
    .search-title-block{
      flex-direction: column;
      gap: 20px;
    }
    .blog-grid {
      margin-top: 0px !important;
  }
  .search-input-container .search-icon {
    left: 32%;
    width: 17px;
    height: 17px;
   }
   .search-bar input {
    padding: 10px;
    width: 35%;
   }
   .search-bar {
    text-align: left;
    margin-bottom: 10px;
   }
}
@media(max-width: 767px){
  .search-input-container .search-icon {
    left: 34%;
  }
  .search-bar input {
    width: 40%;
}
}
  @media(max-width: 575px){
    .blog-grid {
      display: grid;
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
      padding: 0 20px;
    }
    .search-bar input {
      padding: 11px;
      font-size: 14px;
      width: 100%;
    }
    .search-input-container .search-icon {
      width: 17px;
      height: 17px;
      left: 92%;
   
    }
    .blog-grid {
      margin-top: 0px !important;
    }
  }