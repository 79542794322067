.blog-card {
    border-radius: 6px;
    padding: 25px;
    border: 1px solid #17406F;
    cursor: pointer;
  }
  
  .blog-card:hover {
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }
  
  .blog-image {
    width: 100%;
    object-fit: cover;
  }
  
  .blog-date {
    font-size: 14px !important;
    color: #20ACE3;
    margin-bottom: 10px;
    line-height: 34px !important;
    margin-top: 10px;
  }
  
  .blog-title {
    font-size: 24px !important;
    color: #000 !important;
    font-family: 'RalewayRegular';
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 37px !important;
}
  
.blog-card a{
  color: #17406F;
  text-decoration: none;
}
  .blog-description {
    font-size: 16px;
    margin-bottom: 15px;
    line-height: 28px !important;
  }
  
  .blog-link {
    font-size: 16px;
    color: #17406F;
    text-decoration: underline;
    font-family: 'RalewayRegular';
    font-weight: 500;
    text-decoration-style: solid;
  }
  
  .blog-link:hover {
    text-decoration: underline;
  }


  @media(max-width: 991px){
    .blog-title {
      font-size: 22px !important;
      line-height: 35px !important;
     }
     .blog-card {
      padding: 20px;
     }
     
  }
  @media (max-width: 767px) {
    .blog-card {
        padding: 18px;
    }
    .blog-title {
      font-size: 18px !important;
      line-height: 30px !important;
  }
  .blog-link {
    font-size: 15px;
  }
  .blog-description {
    font-size: 15px;
    margin-bottom: 15px;
    line-height: 26px !important;
  }
  .search-bar input {
    padding: 11px;
  }
  }