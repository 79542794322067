.detail-portfolio-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.detail-container {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
.button-group-details {
  display: flex;
  justify-content: flex-start;
  margin: 20px 0;
  gap: 20px;
}
.button-group-details{
  transition: transform 0.3s ease, background-color 0.3s ease; 
}

.custom-button-details:hover {
  transform: scale(1.05); 
  background-color: rgba(52, 152, 219, 0.2); 
}
.custom-button-details {
  padding: 10px 20px;
  border-radius: 6px !important;
  background-color: white !important;
  font-size: 16px;
  border: 1px solid #17406F;
  min-width: 230px;
  transition: background-color 0.3s ease, color 0.3s ease, transform 0.3s ease; 
}
.problems-section {
  padding: 20px 0;
  padding-left: 5.3rem;
  display: flex;
  align-items: baseline;
  gap: 50px;
}

.problems-section h2 {
  color: #FF4B55;
  font-weight: 500;
  font-family: 'InterRegular';
  font-size: 28px !important;
}

.problems-row {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  flex-direction: column;
  gap: 18px;
}

.problem-item {
  display: flex;
  align-items: flex-start;

  text-align: left;
}
.problems-row li{
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 12px;
}
.problems-row ul {
  list-style: none;
  margin: 0;
  padding: 0;
  padding-top: 2px;
  display: flex;
  flex-direction: column;
  gap: 18px;
  
}
.problems-row ul li::before {
  content: url("../../../photos/Vector\ \(10\).svg");
  margin-top: 4px;
  width: 24px;
  height: 24px;
 
}

.problem-text {
  font-size: 18px;
  line-height: 28px;
}
.actions-section {
  padding-left: 5.5rem;
  display: flex;
  align-items: baseline;
  gap: 50px;
}
.actions-section h2 {
  color: #20ACE3;
  font-weight: 500;
  font-family: 'InterRegular';
}
.actions-row {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  gap: 18px;
}
.actions-row ul{
  list-style: none;
    margin: 0;
    padding: 0;
    padding-top: 2px;
    display: flex;
    flex-direction: column;
    gap: 18px;
}
.actions-row li {
  display: flex;
  align-items: flex-start;
  text-align: left;
  gap: 12px;
}
.actions-row.res ul li::before {
  content: url("../../../photos/green-arrow.svg");
  margin-top: 4px;
  width: 24px;
  height: 24px;
}
.actions-row.act ul li::before {
  content: url("../../../photos/Vector\ \(9\).svg");
  margin-top: 4px;
  width: 24px;
  height: 24px;
}
.action-item {
  display: flex;
  align-items: flex-start;

  text-align: left;
}

.action-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
  margin-top: 3px;
}

.action-text {
  font-size: 18px;
  line-height: 28px;
}
.fourth-image {
  margin-top: 20px;
  border: 1px solid #17406F;
  border-radius: 6px;
  padding: 20px;
}
.carousel-detail-container{
  margin-top: 70px;
}
.fourth-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.fifth-image {
  margin-top: 50px;
  position: relative;

}
.link-popup {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  color: black;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 14px;
  visibility: hidden; 
  opacity: 0;
  transition: opacity 0.3s ease;
}
.fifth-image:hover .link-popup {
  visibility: visible;
  opacity: 1; 
}
.fifth-image img {
  width: 100%;
  max-width: 100%;
  height: auto;
}
.solution-title{
  color: #20ACE3 !important;
}
.results-title{
  color: #67970B !important;
}

.characteristics-container {
  margin-top: 85px;
  display: flex;
  justify-content: space-between;
}

.characteristic {
  display: flex;
  align-items: center;
  gap: 20px;

}
.detail-titles{
  align-items: center;
}
.detail-titles h1{
  position: relative;
  color: rgb(32, 172, 227);
  line-height: 45px;
  font-weight: 600;
  font-size: 42px !important;
  font-family: RalewayRegular !important;
  margin-bottom: 20px !important;
}
.detail-titles h5{
 font-size: 18px !important;
 line-height: 24px !important;
 color: #17406F;
 margin-left: 10px;
}
.ch-title {
  font-size: 18px;
  font-weight: 400;
}

span.ch-nb {
  font-family: 'RalewayRegular';
  font-size: 41px;
  color: #17406F;
  font-weight: 500;
  line-height: 40px;
}

.form-detail {
  margin-top: 130px;
}
.voix-detail-page .voix {
  padding: 0px !important;
}
.form-detail .sc-gFqAkR.enUZMz {
  padding: 0px !important;
}
.detail-page-portfolio{
  margin-top: 85px;

}
.detail-page-meet{
  margin-top: 80px;
}
.detail-page-meet h3{
  font-size: 32px !important;
  font-family: "RalewayRegular";
  font-weight: 600;
  color: #000;
  margin-bottom: 10px !important;

}
.detail-meet-rec {
  border: 1px solid #17406F;
  border-radius: 6px;
  padding: 25px;
  margin-top: 15px;
}
.meet-rec-title-btn {
  display: flex;
  align-items: center;
  gap: 15px;

  justify-content: space-between;
}
.meet-rec-title-btn p{
  width: 68%;
}
@media (max-width:1399px) {
  .custom-button-details {
    min-width: 170px;
  }

}

@media (max-width: 1199px) {
  .custom-button-details {
    min-width: 80px;
  }
  .detail-titles h5 {
    margin-left: 0;
  }
  .detail-titles {
    flex-direction: column;
    align-items: flex-start;
  }
  .problems-row {
    display: flex;
    justify-content: space-between;
     margin-top: 0px; 
    flex-direction: column;
}
.problem-text , .action-text {
  font-size: 17px;
  line-height: 28px;
}
.problem-icon , .action-icon {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  margin-top: 7px;
}
.characteristic img{
  width: 90px;
}
span.ch-nb {
  font-size: 34px;
}
}

@media (max-width: 991px) {
  .button-group-details {
    margin: 0;
    gap: 12px;
    flex-wrap: wrap;
  
  }
  .detail-titles h5 {
    font-size: 16px !important;
  }
  .detail-titles h1{
    font-size: 32px !important;
    margin-bottom: 15px !important;

  }
  .problems-section h2 {
    font-size: 22px !important;
}
  .custom-button-details {
    padding: 10px 13px;
 
  }
  .button-group-details img{
    width: 120px;
  }
  .characteristics-container {
        flex-wrap: wrap;
        gap: 20px 14%;
        justify-content: flex-start;
        margin-top: 60px;
}
.carousel-container-detail {
  margin: 0 20px;
}
.carousel-detail-container{
  margin-top: 46px;
}
.form-detail .hQzYMM {
  padding: 0px;
}
.problems-section {
  padding-left: 0;
}
.actions-section {
  padding-left: 0;
}
.detail-page-meet h3 {
  font-size: 28px !important;
}
.meet-rec-title-btn p {
  width: 59%;
}
.detail-page-portfolio {
  margin-top: 57px;
}
.detail-page-meet {
  margin-top: 57px;
}
.container.defi-container.mt-5 {
  margin-top: 30px !important;
}
}
@media (max-width : 767px){
  span.ch-nb {
    font-size: 28px;
  }
  .ch-title {
    font-size: 16px;
    font-weight: 400;
}
.form-detail {
  margin-top: 0px;
}
.problems-section {
  padding-left: 0;
}
.problems-section {
  gap: 20px;
}
.carousel-detail-container {
  margin-top: 50px;
}
.meet-rec-title-btn p {
  width: 100%;
}
.meet-rec-title-btn {
  flex-direction: column;
  align-items: flex-start;
}
.problems-section h2 {
  font-size: 20px !important;
}
.detail-meet-rec {
  border: 1px solid #17406F;
  border-radius: 6px;
  padding: 20px;
  margin-top: 8px;
}
.detail-page-meet h3 {
  font-size: 25px !important;
}
.detail-page-meet {
    margin-top: 50px;
}

.detail-page-portfolio {
  margin-top: 40px;
  /* margin-bottom: 100px; */
}
}
@media (max-width :575px){
  .carousel-detail-container.mt-5 {
    margin: 0 20px;
}
.solution-container,
.defi-container{
  padding: 0 20px !important;
}
.characteristic img {
  width: 67px;
}
span.ch-nb {
  font-size: 20px;
}
.carousel-item-detail {

  padding: 12px;
}
.characteristics-container {
  flex-wrap: wrap;
  gap: 20px 14%;
  justify-content: flex-start;
  align-items: center;
  margin-top: 35px;
}
.container.defi-container.mt-5 {
  margin-top: 18px !important;
}
.container.mt-5.solution-container {
  margin-top: 40px !important;
}
.button-group-details {
  padding: 0 20px;
}
.custom-button-details {
  font-size: 14px;
}
.problems-section {
  flex-direction: column;
  gap: 0px;
}
.problem-icon , .action-icon {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  margin-top: 7px;
}
.problem-text , .action-text {
  font-size: 14px;
  line-height: 27px;
}
.problems-row ul {
  gap: 10px;
}
.carousel-detail-container {
  margin-top: 30px;
}
.detail-titles h1{
  font-size: 24px !important;
  line-height: 36px !important;
  margin-bottom: 8px !important;
}
.detail-titles h5 {
  font-size: 14px !important;
}
.problems-row , .actions-row {
  gap: 10px;
}
.button-group-details img{
  width: 115px;
}
.detail-page-meet {
  margin-top: 40px;
}
}