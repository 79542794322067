.btn.rndv {
  background-color: #20ACE3;
  color: white;
  padding: 10px 20px;
  text-decoration: none;
  will-change: transform;
  transition: .3s ease-in-out;
  transition-delay: .35s;
  border: none;
  overflow: hidden;
  display: inline-block;
  border-radius: 6px;
  z-index: 1;
  position: relative; 
}
.btn.rndv:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #17406F;
  color: #fff;
  transition: left 0.3s ease-in-out;
  z-index: 0;
  border-radius: 6px;
}
.btn.rndv:hover:before {
  left: 0; 

}
.content-my-button{
  position: relative;
}

@media (max-width: 575px){
  .btn.rndv{
    font-size: 14px;
  }
  .btn.rndv svg{
    height: 22px;
    width: 22px;
  }
}