.seo-page-container h3 {
  font-size: 32px !important;
  line-height: 50px !important;
  font-family: 'RalewayRegular';
  font-weight: 600;
  color: #17406F;
}

.seo-services h5 {
  color: #20ACE3;
  font-size: 22px;
  line-height: 34px;
  font-weight: 600;
  font-family: 'RalewayRegular';
}

.concurrence ul {
  list-style-type: none;
}

.concurrence ul p {
  font-size: 18px;
  line-height: 28px !important;
}

.concurrence ul li {
  display: flex;
  align-items: flex-start;
  margin-bottom: 15px;
  font-size: 18px;
  line-height: 28px;
}

.concurrence ul li img {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}

.seo-services {
  padding-left: 6rem;
  margin-top: 15px;
  margin-bottom: 60px;
}

.seo-services .seo {
  align-items: flex-start;
  margin-bottom: 20px;
  gap: 5rem;
}

.seo-services .d-flex>div {
  width: 48%;
}

.seo-img-title {
  margin-bottom: 15px;
}

.autres-services {
  margin-top: 35px;
  gap: 43px;
}

.button-formules {
  display: flex;
  align-items: center;
  gap: 20px;
  font-size: 18px;
  font-weight: 500;
  color: #fff;
  background: #20ACE3;
  border: none;
  border-radius: 6px !important;
  will-change: transform;
  transition: all 0.3s ease-in-out;
  overflow: hidden;
  position: relative;
  z-index: 1;
}

.button-formules:before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background-color: #17406F;
  transition: left 0.3s ease-in-out;
  z-index: 0;
  border-radius: 6px;
}

.button-formules:hover:before {
  left: 0;
}

.button-formules span {
  position: relative;
  z-index: 1;
}


.concurrence {
  margin-top: 122px;
  margin-bottom: 85px;
}

.concurrence-ul {
  margin-top: 14px;
  padding-left: 4rem;
}

.block-container-seo {
  padding: 16px 20px;
  margin: 20px auto;
  border-radius: 6px;
  margin-left: 7rem;
  border: 1px solid #17406F;
}

.block-container-seo .header {
  display: flex;
  justify-content: space-between;
  align-items: center;

}

.block-container-seo p {
  font-size: 18px !important;
  line-height: 32px !important;
  margin-top: 20px;

}

.block-container-seo h3 {
  font-size: 24px !important;
  color: #000;
  font-weight: 400 !important;
}

.MuiInputLabel-formControl {
  font-size: 18px !important;
  font-family: 'InterRegular' !important;

}

.points-forts-seo {
  margin-top: 167px;
  margin-bottom: 100px;
}

.seo-points-layout {
  display: grid;
  grid-template-columns: 1fr 0.5fr 1fr;
  grid-template-rows: auto auto auto;
  gap: 45px 28px;
  align-items: center;
  margin-top: 50px;
}

.seo-point {
  font-size: 26px;
  font-family: 'RalewayRegular';
  font-weight: 400;
  color: #000;
}

.left {
  grid-column: 1;

  justify-self: end;
  display: flex;
  align-items: center;
  height: 100%;
}

.right {
  grid-column: 3;
  justify-self: start;
  display: flex;
  align-items: center;
  height: 100%;
}

.seo-point .content {
  display: flex;
  align-items: center;
}

.bottom {
  grid-column: 1 / span 3;
  text-align: center;
}

.seo-icon {
  grid-column: 2;
  grid-row: 1 / span 2;
  justify-self: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  background-color: transparent;
}
.seo-icon:hover {
  transform: scale(1.1) rotate(5deg);
  cursor: pointer; 
}

.right.r1 {
  grid-row: 1;
}

.right.r2 {
  grid-row: 2;
}

.points-forts-title {
  justify-content: center;
}
.btn-seo{
  will-change: transform;
  transition-delay: .35s;
  overflow: hidden;
  display: inline-block;
  position: relative; 
  z-index: 1; 
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out; 
}
.btn-seo:hover {
  background-color: #17406F; 
  color: #fff;
  border-color: #17406F; 
  z-index: 2; 
}
.btn-seo:hover:before {
  left: 0;
}
.btn-seo span{
  position: relative;
}
.autres-services .autre-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  transform: translateY(-2px);
  cursor: pointer;
}
@media (max-width: 1200px) {
  .seo-point {
    font-size: 23px;
  }
}

@media (max-width: 991px) {
  .seo-page-container h3 {
    font-size: 22px !important;
    line-height: 32px !important;
  }

  .block-container-seo h3 {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .button-formules {
    font-size: 16px;
  }

  .seo-services .seo {
    display: flex;
    /* justify-content: space-between; */
    align-items: flex-start;
    margin-bottom: 15px;
    gap: 52px;
  }

  .seo-img-title {
    margin-bottom: 5px;
  }

  .seo-services h5 {
    font-size: 18px;
    line-height: 24px;
  }

  .seo-services {
    padding-left: 0;

    margin-top: 15px;
    margin-bottom: 22px;
  }

  .autres-services {
    margin-top: 25px;
  }

  .concurrence {
    margin-top: 78px;
    margin-bottom: 35px;
  }

  .concurrence ul p {
    font-size: 16px;
    line-height: 28px !important;
  }

  .concurrence ul li {
    margin-bottom: 5px;
  }

  .block-container-seo p {
    font-size: 16px !important;
    line-height: 28px !important;
    margin-top: 15px;
  }

  .form {
    margin-top: 15px !important;
    margin-left: 0 !important;
  }

  .MuiInputLabel-formControl {
    font-size: 16px !important;
  }

  .form .submit {
    font-size: 16px !important;
  }

  .seo-points-layout {
    gap: 30px 28px;
    margin-top: 27px;
  }

  .seo-point {
    font-size: 21px;
    text-align: center;
  }

  .points-forts-seo {
    margin-bottom: 90px;
  }
}

@media (max-width: 766px) {
  .seo-page-container h3 {
    font-size: 25px !important;
    line-height: 40px !important;
  }

  .seo-services {
    padding-left: 0;
  }

  .block-container-seo h3 {
    font-size: 18px !important;
    font-weight: 400 !important;
    line-height: 25px !important;
  }

  .block-container-seo img {
    width: 58px;
    height: 54px;
  }

  .form {
    margin-top: 15px !important;
    margin-left: 0 !important;
  }

  .block-container-seo {
    border: 1px solid #17406F;
    padding: 18px;
    margin: 20px auto;
    margin-left: 7rem;
  }

  .autres-services {
    flex-direction: column;
    gap: 24px;
  }

  .autres-services .item-margin {
    position: unset !important;
  }

  .concurrence {
    margin-top: 40px;
  }

  .MuiInputLabel-formControl {
    font-size: 14px !important;
  }

  
  .seo-points-layout {
    gap: 30px 0px;
    margin-top: 27px;
  }

  .seo-point {
    font-size: 16px;
    text-align: center;
  }

  .seo-points-layout {
    grid-template-columns: 1fr 2fr;
    grid-template-rows: auto auto auto;
    gap: 20px;
  }

  .left {
    grid-column: 2;
    grid-row: auto;
    justify-self: start;
    text-align: left;
  }

  .right {
    grid-column: 2;
    justify-self: start;
    text-align: left;
  }

  .right.r2 {
    grid-row: auto;
  }

  .right.r1 {
    grid-row: auto;
  }

  .seo-icon {
    grid-column: 1;
    grid-row: 1 / span 5;
    justify-self: start;
  }

  .seo-point.bottom {
    grid-column: 2;
    text-align: left;

  }

  .points-forts-title {
    justify-content: start;
  }

  .seo-point::before {
    content: '';
    display: inline-block;
    width: 5px;
    height: 5px;
    background-color: #20ACE3;
    border-radius: 50%;
    margin-right: 10px;
    vertical-align: middle;
  }
  .points-forts-seo {
    margin-top: 70px;
  }
}

@media (max-width: 575px) {
  .seo-page-container h3 {
    font-size: 20px !important;
    line-height: 30px !important;
  }

  .block-container-seo {
    margin: 15px 20px;
  }

  .block-container-seo h3 {
    font-size: 16px !important;
    line-height: 22px !important;
  }
  .points-forts-seo {
    margin-top: 51px;
    margin-bottom: 61px;
  }

  .block-container-seo img {
    width: 50px;
    height: 47px;
  }

  .seo-services .d-flex>div {
    width: 100%;
  }

  .seo-services .seo {
    flex-direction: column;
  }

  .concurrence {
    padding: 0px 20px;
  }

  .seo-services {
    margin-top: 15px;
    margin-bottom: 0px;
  }

  .seo-services .seo {
    display: flex;
    align-items: flex-start;
    margin-bottom: 15px;
    gap: 15px;
  }

  .points-forts-seo {
    padding: 0px 20px;
  }

  .button-formules {
    font-size: 15px;
    gap: 12px;
  }

  .button-formules svg {
    width: 24px;
    height: 28px;
  }

  .card-header {
    gap: 8px;
  }

  .card-header img {
    width: 55px;
  }

  .seo-icon img {
    width: 120px;
  }
}

@media(max-width: 480px) {
  .seo-icon img {
    width: 97px;
  }

  .seo-points-layout {
    gap: 20px 10px;
  }

  .seo-point {
    font-size: 14px;
  }
}