.sub-text {
    font-family: 'RalewayRegular';
    color: #17406F;
    font-size: 40px;
}
.home-container {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.home-container p {
    max-width: 1390px;
    font-size: 18px;
    line-height: 34px !important;
    margin-bottom: 0;
}

.main-text {
    font-size: 18px;
    font-weight: 400;
}

.agence h1 {
    color: #17406F !important;
    margin-top: 60px !important;
    line-height: 84px !important;
    font-size: 60px !important;
}

.button-group {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
}

.button-group.btn {
    padding: 10px 20px;
    font-size: 16px;
    background-color: #17406F;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    color: #fff;
}

.btn:hover {
    background-color: #17406F;
    color: #fff ;
    border-color: #17406F;
}
.vision,
.portfolio-container,
.nos-services{
    margin-top: 90px !important;
}
.blog-section-home-container {
    margin-top: 90px !important;
}
.row-vision {
    margin-top: 45px;
}

.rendez-vous {
    background-color: #20ACE3;
    color: #fff !important;
    border-radius: 6px !important;
    padding: 12px 62px !important;
    font-size: 20px;
    will-change: transform;
    transition-delay: .35s;
    border: none;
    overflow: hidden;
    display: inline-block;
    position: relative; 
    z-index: 1; 
}

.btn.rendez-vous:before,
.btn.nos-realisations:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #17406F;
    transition: left 0.3s ease-in-out;
    z-index: 0; 
    border-radius: 6px;
}

.btn.rendez-vous:hover:before,
.btn.nos-realisations:hover:before {
    left: 0; 
}

.btn.rendez-vous:hover {
    background-color: #17406F; 
    color: #fff;
    border-color: #17406F;
}

.btn-content-home{
    position: relative;
}
.nos-realisations {
    background-color: #FFFFFF;
    border: 2px solid #20ACE3 !important;
    color: #20ACE3 !important;
    border-radius: 6px !important;
    padding: 12px 49px !important;
    font-size: 20px;
    will-change: transform;
    transition-delay: .35s;
    border: none;
    overflow: hidden;
    display: inline-block;
    position: relative; 
    z-index: 1; 
}
.nos-realisations:hover{
    color: #fff !important;
    border-color: #17406F !important;
}

h2 {
    color: #17406F;
    font-family: 'RalewayRegular';
    font-weight: 600;
}

.voix {
    margin-top: 90px !important;
}
.faq-home{
    margin-top: 115px;
}
.listeOfClient {
    margin-top: 90px;
}
.loading{
    display: flex;
    justify-content: center;
  } 
  .grid-container-vision {
    display: grid;
    grid-template-columns: repeat(4, 1fr); 
    gap: 2rem; 
    margin-bottom: 3rem; 
    margin-top: 40px;
  }
  


  
  .grid-item-vision {
    display: flex;
    align-items: center;
    gap: 1rem;
  
  }
  
  /* .grid-item-vision.animate {
    opacity: 1;
    transform: translateY(0);
  }
   */
  /* .grid-item-vision img {
    width: 100px;
    border-radius: 50%; 
    box-sizing: border-box;
    position: relative;
    z-index: 1; 
  } */
  
  /* .grid-item-vision img:hover {
   
    animation: drawBorder 1s linear forwards;
  } */
  
  /* @keyframes drawBorder {
    0% {
      border: 4px solid transparent;
      transform: scale(1);
    }
    50% {
      border: 4px solid #FF6347; 
      transform: scale(1.2);
    }
    100% {
      border: 4px solid #FF6347;
      transform: scale(1);
    }
  }
   */

  /* .grid-item-vision:nth-child(1) {
    animation-delay: 0.2s;
  }
  
  .grid-item-vision:nth-child(2) {
    animation-delay: 0.4s;
  }
  
  .grid-item-vision:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  .grid-item-vision:nth-child(4) {
    animation-delay: 0.8s;
  }  */
  .grid-container-vision img {
    width: 100px;
    border: 1px solid #17406F;
    border-radius: 50%;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out; 
}

/* .grid-container-vision img:hover {
    animation: rotate-border 1s linear infinite; 
}

@keyframes rotate-border {
    from {
        transform: rotate(0deg); 
    }
    to {
        transform: rotate(360deg); 
    }
} */
.link-blog-page {
    font-size: 16px;
    color: #17406F;
    text-decoration: underline;
    text-underline-offset: 3px !important;
}
.link-blog-home {
    background-color: #20ACE3;
    color: white !important;
    padding: 10px 20px;
    text-decoration: none;
    will-change: transform;
    transition: .3s ease-in-out;
    transition-delay: .35s;
    border: none;
    overflow: hidden;
    display: inline-block;
    border-radius: 6px;
    z-index: 1;
    position: relative;
    width: fit-content;
    margin-left: auto;
    margin-top: 25px;
}
.link-blog-home:before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background-color: #17406F;
    color: #fff;
    transition: left 0.3s ease-in-out;
    z-index: 0;
    border-radius: 6px;
  }
  .link-blog-home:hover:before {
    left: 0; 
  
  }
  .content-blog-button{
    position: relative;
  }
  
.blog-card-home {
    padding-right: 25px;
    border-right: 1px solid #17406F;
    cursor: pointer;
    border-radius: 0px;
    background-color: #fff;
    padding-left: 25px;
    flex: 1;
    color: initial !important;
    text-decoration: none !important;
 }
 .blog-thumb {
    overflow: hidden;
    width: 100%;
    height: auto;
}
.blog-thumb img {
    transition: transform 0.3s linear;
}
.blog-card-home:hover img {
    transform: scale(1.1);
}
.blog-card-home:nth-child(3) {
    border-right: none;
    padding-right: 0px;
}
.blog-card-home:nth-child(1) {
    padding-left: 0px;
}
  
  .blog-card img {
    width: 100%;
    height: auto;
    border-radius: 5px;
  }
  
  .blog-date {
    font-size: 12px;
    color: #888;
  }
  
  .blog-card-home .blog-title {
    font-size: 24px !important;
    color: #000 !important;
    font-family: 'RalewayRegular';
    margin-bottom: 10px;
    font-weight: 500;
    line-height: 37px !important;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  
  .blog-card-home .blog-summary  {
    font-size: 16px !important;
    margin: 10px 0;
    line-height: 28px !important;
    display: -webkit-box;
    line-clamp: 3;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
  
.blog-cards-home{
    border: 1px solid #17406F;
    padding: 25px !important;
    border-radius: 6px;
    margin-top: 30px;
}
  
.link-blog-page:hover {
   text-decoration: underline;
   text-underline-offset: 3px !important;
   color: #20ACE3;
}

.blog-card-home a{
    text-decoration: none !important;
    color: #17406F !important;
}
.align-items-center:hover .link-blog-page,
.align-items-center:hover .arrow-blog {
  color: #20ACE3; 
  transition: color 0.3s ease; 
  text-decoration: none;
}

.align-items-center:hover .arrow-blog {
  transform: translateX(5px); 
  transition: transform 0.3s ease; 
}
.arrow {
    font-size: 1em;
    line-height: 1.5;
    transform: rotate(0deg);
    margin-top: 4px;
    color: #fff;
    margin-left: 5px;
  }
  .arrow-blog {
    font-size: 1em;
    line-height: 1.5;
    transform: rotate(0deg);
    margin-top: 4px;
    color: #17406F;
  }
  .arrow-blog:hover{
    color: #20ACE3;
  }
@media(max-width: 1400px) {
    .sub-text {
        font-size: 34px;
    }

    .main-text {
        font-size: 16px;
    }
}

@media (max-width: 1199px) {
    .sub-text {
        font-size: 30px;
    }

    .main-text {
      font-size: 16px;;
    }

    .testimonial-card p {
        font-size: 14px;
        line-height: 28px !important;
        color: #000;
        margin-bottom: 15px;
        flex-grow: 1;
        font-weight: 400;
    }
    .grid-container-vision img {
        width: 90px;
    }
    .agence h1 {
        color: #17406F !important;
        margin-top: 60px !important;
        line-height: 64px !important;
        font-size: 44px !important;
    }
    .nos-realisations,
    .rendez-vous {
        font-size: 18px;
    }
}
@media (max-width: 1024px) {
.agence h1 {
    color: #17406F;
    margin-top: 60px;
    line-height: 71px !important;
    font-size: 54px !important;
}
.grid-container-vision img {
    width: 110px;
}
.blog-card-home .blog-title {
    font-size: 20px !important;
    line-height: 32px !important;
}
}
@media (max-width: 991px) {
    .agence h1 {
        color: #17406F;
        margin-top: 60px;
        line-height: 64px !important;
        font-size: 44px !important;
    }
    .vision, .portfolio-container, .nos-services, .voix {
        margin-top: 55px !important;
       
    }
    .grid-container-vision {
        grid-template-columns: repeat(2, 1fr);
    }
    .faq-home {
        margin-top: 75px;

    }
    .portfolio-container {
        margin: 0 20px;
    }
    .home-container h2{
        margin-bottom: 7px !important;
        line-height: 36px !important;
    }
    .carousel-services-container {
        margin-top: 20px;
    }
    .faq-container-home {
        margin-top: 20px;
    }
    .blog-card-home:nth-child(2) {
        border-right: none;
        padding-right: 0px;
    }
    .blog-cards-home {
       border: 1px solid #17406F;
       padding: 20px !important;
    }
    .blog-card-home {
        padding-right: 20px;
        padding-left: 20px;
    }
    .blog-cards-home {
        margin-top: 30px;
    }
    .home-container p {
        max-width: 1390px;
        font-size: 16px;
        line-height: 28px !important;
        margin-bottom: 0;
    }
    .link-blog-page {
        font-size: 15px;
    }
    .blog-section-home-container {
        margin-top: 75px !important;
    }
    .blog-card-home .blog-summary {
        line-clamp: 2;
        -webkit-line-clamp: 2;
    }
}

@media(max-width: 767px) {
    .row-vision img {
        width: 40%;
    }
    .agence h1 {
        color: #17406F;
        margin-top: 70px !important;
        line-height: 56px !important;
        font-size: 36px !important;
    }
    .faq-home {
        margin-top: 46px;
    }
    .blog-card-home .blog-title {
        font-size: 18px !important;
        line-height: 28px !important;
        margin-bottom: 6px;
    }
    .blog-card-home .blog-summary {
        margin: 6px 0;
    }
    .blog-section-home-container {
        margin-top: 46px !important;
    }
}

@media(max-width: 575px) {
    .agence,.vision,.voix{
        padding-left: 20px !important;
        padding-right: 20px !important;
    }
 
    .agence h1 {
        color: #17406F;
        margin-top: 60px !important;
        line-height: 41px !important;
        font-size: 26px !important;
    }

    .row-vision {
        margin-top: 10px;
    }

    .home-container p {
       max-width: 1390px;
       font-size: 14px;
       line-height: 26px !important;
    }
    
    h2 {
        font-size: 20px !important;
        text-align: left !important;
        margin-bottom: 0px !important;
    }
    .home-container h2 {
        margin-bottom: 5px !important;
        line-height: 28px !important;
    }

    .nos-services {
        margin-top: 10px;
    }

    .slick-slide {
        margin-right: 0 !important;
    }

    .button-group {
        font-size: 14px;
    }
    .nos-realisations {
        font-size: 15px;
    }
    .rendez-vous {
        font-size: 15px;
    }
    .voix {
        margin-top: 25px !important;
        margin-bottom: 0px !important;
    }

    .sub-text {
        font-size: 19px;
    }

    .main-text {
        font-size: 14px;
    }
    .vision, 
    .portfolio-container, 
    .nos-services {
        margin-top: 40px !important;
    }
    .portfolio-container{
        margin: 0 20px;
    }
    .row-vision img {
        width: 30%;
    }
    .grid-container-vision img{
        max-width: 53% !important;
    }
    .grid-container-vision {
        margin-bottom: 2rem;
        margin-top: 30px;
    }
    .container.faq-home {
        padding: 0 20px !important;
    }
    .container.blog-section-home-container {
        padding: 0 20px !important;
    }
    .blog-card-home {
        border-right: none;
        padding-right: 0;
    }
    .blog-card-home img{
        width: 100%;
    }
    .blog-card-home .blog-summary {
        font-size: 14px !important;
        line-height: 25px !important;
    }
    .link-blog-page {
        font-size: 14px;
    }
    .content-blog-button {
        font-size: 14px;
    }
    .blog-cards-home {
        margin-top: 24px;
    }
}
@media (max-width: 527px) {
    .nos-realisations {
        padding: 12px 28px !important;
    }
    .rendez-vous {
        padding: 12px 37px !important;
    }
}
@media (max-width: 460px) {
    .grid-container-vision img{
        max-width: 47% !important;
    }
    
  }
@media (max-width: 426px) {
    .button-group {
        display: flex;
        justify-content: flex-start;
        gap: 10px;
        flex-direction: column;
        width: 70%;
        margin-bottom: 20px !important;
    }
}