.list-portfolio-carousel {
  max-width: 1320px; 
  margin: 0 auto;   
  overflow: hidden; 
  margin-top: 15px;
}

.list-portfolio-carousel .image{
 padding: 20px;
 padding-bottom: 10px;
  }

.list-portfolio-carousel .card-header.d-flex.align-items-center.gap-4 {
    background-color: transparent;
    border: 0;
    padding-top: 20px;
    padding-left: 0px;
    padding-bottom: 0;
}
.list-portfolio-carousel .card {
    border: 1px solid #17406F;
    border-radius: 6px;
}
.list-portfolio-carousel h3.card-title {
    font-family: 'RalewayRegular';
    font-size: 20px !important;
    margin-bottom: 0;
}


.list-portfolio-carousel .swiper {
  width: 100%;    
}
.list-portfolio-carousel .slick-dots li button:before {
    font-family: 'slick';
    font-size: 14px;
    line-height: 38px;
    position: absolute;
    top: 0;
    left: 0;
    width: 15px;
    height: 15px;
    content: '';
    text-align: center;
    opacity: .25;
    border-radius: 50%;
    border: 0.5px solid #17406F;
    background-color: transparent;
    color: transparent;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.list-carousel-item {
    border: 1px solid #17406F;
    height: auto;
    border-radius: 6px;
    padding: 20px;
}
.list-portfolio-carousel .slick-slide {
    margin-right: 20px;
    padding: 1px;
}
.slick-slide:not(.slick-cloned):last-of-type {
    margin-right: 0;
  }
.list-portfolio-carousel .slick-track {
    display: flex;
    align-items: center; 
  }
.list-portfolio-carousel .slick-dots {
    position: absolute;
    bottom: -45px;
  }

  .list-portfolio-carousel .slick-dots li button:before {
    font-family: 'slick';
    font-size: 14px;
    line-height: 38px;
    position: absolute;
    top: 0;
    left: 0;
    width: 17px;
    height: 17px;
    content: '';
    text-align: center;
    opacity: .25;
    border-radius: 50%; 
    border: 0.5px solid #17406F;
    background-color: transparent;
    color: transparent; 
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  .slick-dots li.slick-active button:before {
    opacity: 1;
    color: #17406F !important;
    background-color: #17406F !important;
  
  }
  .img-list{
    width: 100%;
    height: auto;
  }

.list-carousel-item:hover {
  background-color: #20ACE3; 
  border: none;
  box-shadow: 0px 5px 11px 0px #20ACE366;

}
.list-carousel-item:hover .card-title,
.list-carousel-item:hover svg path {
    color:  #fff;
    fill:  #fff;
    
}
  @media(max-width: 1199px){
    
  }
  @media(max-width: 991px){
    .list-portfolio-carousel h3.card-title {
        font-size: 18px !important;
    }
  }
  @media(max-width: 890px){
  
    .list-portfolio-carousel {
      margin-top: 10px;
  }
  }
@media(max-width: 796px) and (min-width:768px){
  .list-portfolio-carousel .swiper-pagination {
    margin-top: 0px !important;
  }
}
@media(max-width: 575px){
    .list-carousel-item {
      height: auto;
      padding: 13px;
    }
    .list-portfolio-carousel {
      margin-top: 10px;
  }
    .list-portfolio-carousel .card-header.d-flex.align-items-center.gap-4 {
        padding-top: 13px;
    }
    .list-portfolio-carousel h3.card-title {
        font-size: 16px !important;
        line-height: 22px !important;

    }
    .list-portfolio-carousel .slick-slider {
        margin-top: 0px;
    }
}
@media(max-width:365px){
  .list-portfolio-carousel .swiper-pagination {
    margin-top: 0px !important;
  }
}