.importance-app-mobile-container{
    margin-top: 70px;
}
.questions-mobile{
  margin-top: 86px;
}
.mobile-services h5 {
    color: #20ACE3;
    font-size: 22px;
    line-height: 34px;
    font-weight: 600;
    font-family: 'RalewayRegular';
  }
  .mobile-services {
    margin-top: 15px;
  }
  
  .mobile-services .mobile {
    align-items: flex-start;
      margin-bottom: 20px;
      gap: 5rem;
  }
  
  .mobile-services .d-flex>div {
    width: 48%;
  }
  
  .mobile-img-title {
    margin-bottom: 15px; 
  }
  .mobile-cards {
    display: flex;
    margin-top: 20px;
    align-content: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 20px;
  }
  .mobile-card {
    width: 32%;
    border: 1px solid #17406F;
    padding: 30px 30px;
    border-radius: 6px;
    text-align: left;
  }

  .mobile-card:hover{
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    transform: translateY(-2px);
    cursor: pointer;
  }
  
  .mobile-card-icon {
    margin-bottom: 15px;
  }
  
  .mobile-card-title {
    font-size: 26px !important;
    margin-bottom: 15px;
    font-family: 'RalewayRegular';
    font-weight: 400;
    color: #000;
    line-height: 42px !important;
}
.rec-image-mobile{
  border: 1px solid #17406F;
  border-radius: 6px;
  padding: 18px;
  display: flex;
  justify-content: center;

}
.cards-images-mobile-dev-page{
  display: flex;
  gap: 25px;
  justify-content: center;
  margin-top: 60px;
  width: 100vw;
  margin-left: calc(-50vw + 50%);
}
.mobile-card-text {
  font-size: 16px !important;
  line-height: 30px !important;
  margin-bottom: 0;
}
.mobile-choisir-oxton {
  margin-top: 70px;
}
@media(max-width: 1399px){
  .mobile-card-title {
    font-size: 24px !important;
    line-height: 35px !important;
}
}
@media (max-width: 1199px){
  .mobile-card {
    width: 48%;
  }
  .mobile-cards {
    justify-content: start;
  }
}
@media(max-width : 991px){
    .mobile-services .mobile {
        display: flex;
        align-items: flex-start;
        margin-bottom: 15px;
        gap: 52px;
      }
      .mobile-services h5 {
        font-size: 18px;
        line-height: 24px;
      }
    
      .mobile-services {
        margin-top: 15px;
        margin-bottom: 22px;
      }
      .mobile-img-title {
        margin-bottom: 5px;
      }
      .mobile-card-title {
        font-size: 22px !important;
        line-height: 32px !important;
    }
    .mobile-card-text {
        font-size: 14px !important;
        line-height: 26px !important;
    }
    .mobile-card {
        padding: 18px 20px;
    }
    .cards-images-mobile-dev-page {
      margin-top: 10px;
      width: auto;
      margin-left: auto;
    }
    .importance-app-mobile-container {
      margin-top: 50px;
    }
    .mobile-choisir-oxton {
      margin-top: 56px;
    }
    .questions-mobile {
      margin-top: 60px;
  }
  .rec-im-3{
    display: none;
  }
    
}
@media (max-width: 767px) {
    .mobile-services {
        padding-left: 0;
      }
      .mobile-cards {
        flex-direction: column;
        margin-top: 20px;
        gap: 0;
      }
      .cardc2 {
          position: unset;
      }
    
      .mobile-card {
        width: 100%;
        margin-bottom: 20px;
      }
      .mobile-card-icon img {
        height: 62px;
      }
      .cards-images-mobile-dev-page {
        width: auto;
        margin-left: auto;
        flex-direction: column;
    }
    .mobile-card-title {
      font-size: 20px !important;
      line-height: 28px !important;
      margin-bottom: 8px;
  }
  .rec-im-1 ,
  .rec-im-3{
    display: none;
  }
}
@media (max-width: 575px){
  .cards-images-mobile-dev-page {
    padding: 0 20px;
  }
    .importance-app-mobile-container{
        padding: 0px 20px;
    }
    .mobile-services .d-flex>div {
        width: 100%;
      }
      .mobile-services .mobile {
        flex-direction: column;
      }
      .concurrence{
        padding: 0px 20px;
      }
      .mobile-services {
        margin-top: 0px;
        margin-bottom: 0px;
    }
    .mobile-services .mobile {
      display: flex;
      align-items: flex-start;
      margin-bottom: 15px;
      gap: 15px;
      margin-top: 15px;
    }
    .mobile-cards {
      margin-top: 12px;
      gap: 0px;
  }
  .mobile-card-icon img{
    width: 58px;
    height: 57px;
  }
  .mobile-choisir-oxton {
    padding: 0 20px;
   }
   .questions-mobile {
    padding: 0 20px;
    }
    .importance-app-mobile-container {
      margin-top: 40px;
  }
  .mobile-choisir-oxton {
    margin-top: 40px;
  }
  .questions-mobile {
    margin-top: 30px;
  }
  
 
}